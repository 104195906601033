import styled from 'styled-components';
import { Card } from 'antd';

const StyledCard = styled(Card)`
  .ant-card-body {
    background: ${({ darkbackground }) => (darkbackground ? '#000' : '#fff')} !important;
  }
`;

export default StyledCard;
