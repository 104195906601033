export default [
  {
    content: 'Create your first repository by clicking in the button bellow',
    disableBeacon: true,
    hideFooter: true,
    placement: 'top',
    spotlightClicks: true,
    target: '#new-repository-button',
    title: 'Create Repository',
  },
];
