import React, { Component } from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';

import { Title } from '../../components';
import { locked } from '../../assets';

class PrivateRepo extends Component {
  render() {
    return (
      <>
        <Title text="locked repository" />
        <Row type="flex" justify="center" align="middle">
          <Col xs={22} md={16} lg={14} style={{ textAlign: 'center' }}>
            <div style={{ margin: '36px 0' }}>
              <img width="60%" height="auto" src={locked} alt="404" />
            </div>
            <div style={{ margin: '48px 0' }}>
              <Link to="/">
                <Button type="primary" size="large">
                  Back Home
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default PrivateRepo;
