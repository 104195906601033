import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { Input, Select, Card, Button, message } from 'antd';
import shortId from 'short-id';
import { ChromePicker } from 'react-color';

import Modal from './styles/Modal.styles';
import { generateId, typeExists } from '../../utils/helpers';
import { Spin } from '../../components';
import { firestore, FieldValue } from '../../utils/firebase';
import { defaultColors } from '../../utils/defaults';

const initialState = {
  isModalVisible: false,
  type: undefined,
  isLoading: false,
  name: '',
  hex: '#252525',
};

class AddColor extends Component {
  state = initialState;

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  hideModal = (force) => {
    if (!force && this.state.isLoading) return;
    this.setState(initialState);
  };

  handleChangeType = (type) => {
    this.setState({ type });
  };

  handleSubmit = async () => {
    const { hex } = this.state;
    let { type, name } = this.state;
    const { repoId } = this.props;
    const { colors } = this.props;
    const sort = colors.length + 1;

    if (type === '*') {
      name = name.trim();
      type = generateId(name);
    } else {
      const c = defaultColors.filter((d) => d.type === type)[0];
      name = c.name || type;
    }

    if (type.length < 3 || name.length > 32 || name.length < 3) {
      return message.error('Please provide a valid type name');
    }

    if (hex.length < 3) {
      return message.error('Please provide a valid color');
    }

    try {
      this.setState({ isLoading: true });
      colors.push({ hex, name, type, sort, id: shortId.generate() });
      const newValue = {
        colors,
        updatedAt: FieldValue.serverTimestamp(),
      };
      const merge = { merge: true };
      await firestore.doc(`repositories/${repoId}`).set(newValue, merge);
      this.hideModal(true);
    } catch (error) {
      message.error(error.message || 'Error');
      this.setState({ isLoading: false });
    }
  };

  checkBtnEnabled = () => {
    const { type, name, hex } = this.state;
    return type === '*' ? name && name.length > 3 && hex : type && hex;
  };

  render() {
    return (
      <>
        <Button icon={<PlusOutlined />} size="large" type="primary" onClick={this.showModal}>
          Add color <small> hex</small>
        </Button>
        <Modal
          title="Add color"
          footer={null}
          style={{ top: 20 }}
          onCancel={this.hideModal}
          visible={this.state.isModalVisible}
        >
          <Spin spinning={this.state.isLoading} style={{ textAlign: 'center' }}>
            <Card hoverable>
              <div className="flex-center">
                <div className="color-type">
                  Color type:
                  <Select value="HEX" style={{ flex: 1, marginLeft: 16 }}>
                    <Select.Option value="HEX">HEX</Select.Option>
                    <Select.Option disabled value="CMYK">
                      CMYK
                    </Select.Option>
                  </Select>
                </div>
                <ChromePicker
                  width={240}
                  presetColors={[]}
                  disableAlpha
                  color={this.state.hex}
                  onChange={({ hex }) => this.setState({ hex })}
                />
              </div>
            </Card>
            <div style={{ display: 'flex', marginTop: 12 }}>
              <Select
                size="large"
                placeholder="2. Color type"
                value={this.state.type}
                onChange={this.handleChangeType}
                style={{ flex: 1, marginRight: 12 }}
              >
                <Select.OptGroup label="Default">
                  {defaultColors.map((c) => (
                    <Select.Option
                      key={c.type}
                      value={c.type}
                      disabled={typeExists(this.props.colors, c.type)}
                    >
                      {c.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
                <Select.OptGroup label="Other">
                  <Select.Option value="*">Other</Select.Option>
                </Select.OptGroup>
              </Select>
              {this.state.type === '*' && (
                <Input
                  autoFocus
                  name="name"
                  size="large"
                  placeholder="2.1 Name"
                  maxLength={32}
                  value={this.state.name}
                  style={{ flex: 1, marginRight: 12 }}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              )}
              <Button
                size="large"
                type="primary"
                onClick={this.handleSubmit}
                disabled={!this.checkBtnEnabled()}
              >
                3. Submit
              </Button>
            </div>
          </Spin>
        </Modal>
      </>
    );
  }
}

AddColor.propTypes = {
  colors: PropTypes.array,
  repoId: PropTypes.string.isRequired,
};

AddColor.defaultProps = {
  colors: [],
};

export default AddColor;
