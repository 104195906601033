import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Slider, Col, Input } from 'antd';

import { Spin } from '../../components';
import FontCardStyles from './styles/FontCardStyles.styles';

class FontCard extends Component {
  state = {
    isLoading: false,
    value: 'The quick brown fox jumps over the lazy dog',
    fontSize: 20,
  };

  handleChangeInput = (e) => {
    this.setState({ value: e.target.value });
  };

  handleChangeFontSize = (fontSize) => {
    this.setState({ fontSize });
  };

  render() {
    const marks = { 0: '10px', 100: '110px' };
    const { name, fontFamily, postScriptName, url } = this.props.font;

    return (
      <Col xs={22} md={12} lg={12} style={{ padding: 8 }}>
        <Spin spinning={this.state.isLoading}>
          <FontCardStyles
            fonturl={url}
            fontname={postScriptName}
            style={{ width: '100%' }}
            className="ant-card-bordered-shadow"
            title={(
              <span>
                {name} <span style={{ fontWeight: 'bold' }}>{fontFamily}</span>
              </span>
            )}
            extra={(
              <a href={url} target="_blank" rel="noreferrer noopener">
                <Button icon={<DownloadOutlined />} />
              </a>
            )}
          >
            <Slider
              step={1}
              marks={marks}
              value={this.state.fontSize}
              onChange={this.handleChangeFontSize}
              tipFormatter={(v) => `${v + 10}`}
            />
            <Input.TextArea
              value={this.state.value}
              onChange={this.handleChangeInput}
              style={{
                fontSize: this.state.fontSize + 10,
                fontFamily: postScriptName,
              }}
            />
          </FontCardStyles>
        </Spin>
      </Col>
    );
  }
}

FontCard.propTypes = {
  repoName: PropTypes.string.isRequired,
  font: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

FontCard.defaultProps = {};

export default FontCard;
