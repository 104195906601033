const actions = {
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
};

const updateSettings = (updates) => ({
  type: actions.UPDATE_SETTINGS,
  payload: updates,
});

export { actions, updateSettings };
