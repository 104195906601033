import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Row, Col } from 'antd';

class Title extends React.PureComponent {
  render() {
    return (
      <Row type="flex" justify="center" align="middle" style={this.props.style}>
        <Col xs={22} md={16} lg={14}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 36, marginBottom: 12 }}>
            <Divider>
              {this.props.text && (
                <h3 id={this.props.text} className="title-text">
                  {this.props.text.toLowerCase()}
                </h3>
              )}
            </Divider>
          </div>
        </Col>
        {this.props.subtitle && (
          <Col xs={22} md={16} lg={12}>
            <h4 className="subtitle-text">{this.props.subtitle}</h4>
          </Col>
        )}
      </Row>
    );
  }
}

Title.propTypes = {
  text: PropTypes.string,
  subtitle: PropTypes.string,
};

Title.defaultProps = {
  text: '',
  subtitle: '',
};

export default Title;
