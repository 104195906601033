import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortId from 'short-id';
import { ColumnHeightOutlined, ColumnWidthOutlined, DownloadOutlined } from '@ant-design/icons';
import { Checkbox, Input, Button, Col, Popover, Tooltip, message } from 'antd';
import { ReactSVG } from 'react-svg';

import LogoCardStyles from './styles/LogoCard.styles';

class LogoCard extends Component {
  state = {
    id: shortId.generate(),
    pngPopoverVisible: false,
    jpgPopoverVisible: false,
    svgPopoverVisible: false,
    width: 256,
    height: 256,
    ratio: 0,
    aspectRatio: '',
    isRatioChecked: false,
  };

  downloadSVG = () => {
    this.downloadLogo('image/svg+xml', 'svg', 1024, 1024);
  };

  downloadPNG = () => {
    this.downloadLogo('image/png', 'png');
  };

  downloadJPG = () => {
    this.downloadLogo('image/jpeg', 'jpg');
  };

  downloadLogo = async (type, ext, defaultWidth, defaultHeight) => {
    try {
      const { logo, repoName } = this.props;
      const { id } = this.state;
      const width = defaultWidth || this.state.width;
      const height = defaultHeight || this.state.height;
      const svgId = `svg-${id}`;
      const svg = document.getElementById(svgId);
      svg.setAttribute('width', width);
      svg.setAttribute('height', height);

      await svg.toDataURL(type, {
        callback: (data) => {
          const a = document.getElementById(`data-${id}`);
          a.setAttribute('download', `${repoName}-${logo.name}.${ext}`);
          a.href = data;
          a.click();
        },
      });
    } catch (error) {
      console.error(error);
      message.error('Error');
    }
  };

  handleChangeInput = (e) => {
    const { name, value } = e.target;
    const reg = /^[0-9]*$/;
    if ((!isNaN(value) && reg.test(value)) || value === '') {
      if (value < 0 || value > 5000) {
        return message.error('5000 is the maximum value');
      }

      this.setState({ [name]: value });
      if (this.state.isRatioChecked) {
        const { ratio } = this.state;
        if (name === 'width') {
          this.setState({ height: parseInt(value / ratio) });
        } else {
          this.setState({ width: parseInt(value * ratio) });
        }
      }
    }
  };

  handleChangeRatioCheckbox = (e) => {
    const { checked } = e.target;
    this.setState({ isRatioChecked: checked });
    if (checked) {
      const { width, ratio } = this.state;
      this.setState({ height: parseInt(width / ratio) });
    }
  };

  renderDownloadActions = () => {
    const content = (downloadFunc) => (
      <>
        <Input.Group compact>
          <Input
            name="width"
            value={this.state.width}
            style={{ width: '90px' }}
            onChange={this.handleChangeInput}
            prefix={<ColumnWidthOutlined />}
          />
          <Input
            name="height"
            style={{ width: '90px' }}
            value={this.state.height}
            onChange={this.handleChangeInput}
            prefix={<ColumnHeightOutlined />}
          />
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={downloadFunc}
            style={{ width: '60px' }}
          />
        </Input.Group>
        <div
          style={{
            color: '#666',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            marginTop: 8,
          }}
        >
          <Checkbox checked={this.state.isRatioChecked} onChange={this.handleChangeRatioCheckbox}>
            Maintain aspect ratio:
{' '}
            <strong>{this.state.aspectRatio}</strong>
          </Checkbox>
          <Tooltip placement="bottom" title="Coming soon">
            <Checkbox style={{ marginTop: 6 }} disabled>
              Download @1x, @2x, and @3x
            </Checkbox>
          </Tooltip>
        </div>
      </>
    );

    const svgContent = (
      <Input.Group compact style={{ display: 'flex' }}>
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          style={{ flex: 1 }}
          onClick={this.downloadSVG}
        />
      </Input.Group>
    );

    return [
      <Popover
        trigger="click"
        title="Download svg"
        content={svgContent}
        visible={this.state.svgPopoverVisible}
        onVisibleChange={(svgPopoverVisible) => this.setState({ svgPopoverVisible })}
      >
        <Button icon={<DownloadOutlined />} type="link" size="small">
          svg
        </Button>
      </Popover>,
      <Popover
        title="Download png"
        trigger="click"
        style={{ width: '140px' }}
        content={content(this.downloadPNG)}
        visible={this.state.pngPopoverVisible}
        onVisibleChange={(pngPopoverVisible) => this.setState({ pngPopoverVisible })}
      >
        <Button type="link" size="small">
          png
        </Button>
      </Popover>,
      <Popover
        trigger="click"
        title="Download jpg"
        style={{ width: '140px' }}
        content={content(this.downloadJPG)}
        visible={this.state.jpgPopoverVisible}
        onVisibleChange={(jpgPopoverVisible) => this.setState({ jpgPopoverVisible })}
      >
        <Button type="link" size="small">
          jpg
        </Button>
      </Popover>,
    ];
  };

  render() {
    const { logo } = this.props;
    const { id } = this.state;
    return (
      <Col xs={22} md={8} lg={6} style={{ padding: 8 }}>
        <LogoCardStyles
          style={{ width: '100%' }}
          className="ant-card-bordered-shadow"
          actions={this.renderDownloadActions()}
          darkbackground={logo.type.includes('white') || logo.darkBackground ? 1 : 0}
          title={(
            <div className="card-title">
              <span style={{ fontWeight: 500 }}>{logo.name}</span>
            </div>
          )}
        >
          <ReactSVG
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'height: 160px; width: 100%');
              svg.setAttribute('id', `svg-${id}`);
            }}
            afterInjection={(err, svg) => {
              if (err) {
                console.error(err);
                return;
              }
              try {
                const width = parseInt(svg.getAttribute('width'), 10);
                const height = parseInt(svg.getAttribute('height'), 10);
                const ratio = width / height;
                const aspectRatio = `${parseInt(width / ratio)}:${parseInt(height / ratio)}`;
                if (!this.state.aspectRatio) {
                  this.setState({ aspectRatio, ratio });
                }
              } catch (error) {
                console.error(error);
              }
            }}
            src={logo.url}
          />
          <div style={{ display: 'none' }}>
            <a
              href="data:"
              target="_blank"
              download="download"
              rel="noreferrer noopener"
              id={`data-${id}`}
            >
              .
            </a>
          </div>
        </LogoCardStyles>
      </Col>
    );
  }
}

LogoCard.propTypes = {
  logo: PropTypes.object.isRequired,
  repoName: PropTypes.string.isRequired,
};

LogoCard.defaultProps = {};

export default LogoCard;
