import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Row, Col } from 'antd';

class FAQ extends Component {
  render() {
    const faq = this.props.faq.sort((a, b) => a.sort - b.sort);
    return (
      <Row type="flex" justify="center" align="middle">
        <Col xs={22} md={20} lg={18}>
          <Row type="flex" justify="center" align="middle" gutter={8}>
            <Collapse bordered={false} style={{ width: '100%' }}>
              {faq.map((f) => (
                <Collapse.Panel
                  key={f.id}
                  header={<strong style={{ fontSize: 16 }}>{f.question}</strong>}
                >
                  {f.answer}
                </Collapse.Panel>
              ))}
            </Collapse>
          </Row>
        </Col>
      </Row>
    );
  }
}

FAQ.propTypes = {
  faq: PropTypes.array,
};

FAQ.defaultProps = {
  faq: [],
};

export default FAQ;
