import React, { Component } from 'react';
import pathOr from 'ramda/src/pathOr';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import { Helmet } from 'react-helmet';

import { RepoTitle, Title, Loading, NotFound } from '../../components';
import { database, firestore, functions } from '../../utils/firebase';
import PasswordView from './PasswordView';
import Logos from './Logos';
import Colors from './Colors';
import Fonts from './Fonts';
import Resources from './Resources';
import FAQ from './FAQ';
import SubFooter from './SubFooter';
import PrivateRepo from './PrivateRepo';

class Repo extends Component {
  state = {
    isLoading: true,
    notFound: false,
    withPassword: false,
    isLoadingPass: false,
    privateRepo: false,
  };

  componentDidMount() {
    this.getDocument();
  }

  getDocument = async () => {
    const id = pathOr(null, ['match', 'params', 'id'], this.props);
    try {
      if (!id) {
        this.props.history.push('/');
        return;
      }
      const doc = await firestore.doc(`repositories/${id}`).get();
      this.setState({ ...doc.data(), repoId: id });
      this.increaseCounter();
    } catch (error) {
      const checkRepo = functions.httpsCallable('checkRepo');
      const result = await checkRepo({ id });
      if (result.data.exists) {
        if (result.data.private) {
          this.setState({ privateRepo: true });
        } else {
          this.setState({ withPassword: true });
        }
      } else {
        this.setState({ notFound: true });
      }
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleGetRepoWithPass = async (password) => {
    const id = pathOr(null, ['match', 'params', 'id'], this.props);
    try {
      this.setState({ isLoadingPass: true });
      const getRepoWithPass = functions.httpsCallable('getRepoWithPass');
      const result = await getRepoWithPass({ password, id });
      if (result.data) {
        this.setState({ ...result.data, repoId: id, withPassword: false });
        this.increaseCounter();
      } else {
        message.error('Wrong password');
      }
    } catch (error) {
      message.error(error.message || 'Error');
    } finally {
      this.setState({ isLoadingPass: false });
    }
  };

  increaseCounter = async () => {
    try {
      const ref = database.ref(`repositoriesViews/${this.state.repoId}/total`);
      await ref.transaction((current) => (current || 0) + 1);
    } catch (e) {}
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    if (this.state.notFound) {
      return <NotFound text="repository not found" subtitle="" />;
    }

    if (this.state.privateRepo) {
      return <PrivateRepo />;
    }

    if (this.state.withPassword) {
      return (
        <PasswordView
          isLoading={this.state.isLoadingPass}
          handleGetRepoWithPass={this.handleGetRepoWithPass}
        />
      );
    }
    const pageTitle = `${this.state.name} - GitBrand`;
    return (
      <div style={{ marginBottom: 36 }}>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={this.state.description} />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={`Take a look at ${this.state.name}'s brand`} />
          <meta property="og:image" content={this.state.logoUrl} />
          <meta property="og:url" content={`https://gitbrand.com/${this.state.repoId}`} />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={`Take a look at ${this.state.name}'s brand`} />
          <meta name="twitter:image" content={this.state.logoUrl} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <RepoTitle
          url={this.state.url}
          name={this.state.name}
          repoId={this.state.repoId}
          members={this.state.members}
          isPublic={this.state.isPublic}
          updatedAt={this.state.updatedAt}
          description={this.state.description}
        />

        {(this.state.logos || []).length > 0 && (
          <>
            <Title text="logos" />
            <Logos logos={this.state.logos} repoName={this.state.name} />
          </>
        )}

        {(this.state.colors || []).length > 0 && (
          <>
            <Title text="colors" style={{ marginTop: 60 }} />
            <Colors colors={this.state.colors} repoName={this.state.name} />
          </>
        )}

        {(this.state.fonts || []).length > 0 && (
          <>
            <Title text="fonts" style={{ marginTop: 60 }} />
            <Fonts fonts={this.state.fonts} repoName={this.state.name} />
          </>
        )}

        {(this.state.resources || []).length > 0 && (
          <>
            <Title text="resources" style={{ marginTop: 60 }} />
            <Resources resources={this.state.resources} />
          </>
        )}

        {(this.state.faq || []).length > 0 && (
          <>
            <Title text="faq" style={{ marginTop: 60 }} />
            <FAQ faq={this.state.faq} />
          </>
        )}

        <Title text="share" style={{ marginTop: 60 }} />
        <SubFooter name={this.state.name} />
        <br />
      </div>
    );
  }
}

export default withRouter(Repo);
