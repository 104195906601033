import React from 'react';
import PropTypes from 'prop-types';
import { LinkOutlined } from '@ant-design/icons';
import { Button, Row, Col, Table, Tag } from 'antd';

import { getExtensionColor } from '../../utils/helpers';

function Resources({ resources }) {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'File Type',
      key: 'ext',
      dataIndex: 'ext',
      render: (ext) => <Tag color={getExtensionColor(ext)}>{(ext || '').toUpperCase()}</Tag>,
      filters: [
        { text: 'pdf', value: 'pdf' },
        { text: 'zip', value: 'zip' },
        { text: 'png', value: 'png' },
        { text: 'jpg', value: 'jpg' },
        { text: 'docx', value: 'docx' },
        { text: 'xlsx', value: 'xlsx' },
        { text: 'pptx', value: 'pptx' },
        { text: 'html', value: 'html' },
      ],
      onFilter: (value, record) => (record.ext || '').indexOf(value) === 0,
    },
    {
      title: 'Link',
      key: 'actions',
      dataIndex: 'actions',
      render: (a, r) => (
        <a href={r.url} target="_blank" rel="noreferrer noopener">
          <Button icon={<LinkOutlined />} />
        </a>
      ),
    },
  ];
  return (
    <Row type="flex" justify="center" align="middle">
      <Col xs={22} md={20} lg={18}>
        <Row type="flex" justify="center" align="middle" gutter={8}>
          <Table
            bordered
            size="small"
            rowKey="type"
            columns={columns}
            pagination={false}
            style={{ width: '100%' }}
            dataSource={resources.sort((a, b) => a.sort - b.sort)}
          />
        </Row>
      </Col>
    </Row>
  );
}

Resources.propTypes = {
  resources: PropTypes.array,
};

Resources.defaultProps = {
  resources: [],
};

export default Resources;
