import '@ant-design/compatible/assets/index.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import { Modal, Card, Divider, Button, Typography, Row, Col, message as messageAnt } from 'antd';

import { update } from '../../redux/user/actions';
import { firebase, auth } from '../../utils/firebase';

class ProfileForm extends Component {
  state = {};

  handleChangeName = async (displayName) => {
    try {
      this.props.update({ displayName });
      const user = auth.currentUser;
      await user.updateProfile({ displayName });
    } catch (error) {
      messageAnt.error(error.message);
    }
  };

  handleChangeEmail = async (email) => {
    try {
      this.props.update({ email });
      const user = auth.currentUser;
      await user.updateEmail(email);
    } catch (error) {
      messageAnt.error(error.message);
    }
  };

  handleLogout = async () => {
    try {
      await auth.signOut();
      this.props.history.push('/');
    } catch (error) {
      messageAnt.error(error.message);
    }
  };

  handleDeleteAccount = () => {
    Modal.confirm({
      title: 'Do you want to delete your account?',
      content: (
        <span>
          All your repositories will be deleted.
          <br />
          <strong>CAUTION: This action cannot be undone</strong>
        </span>
      ),
      onOk: async () => {
        try {
          await auth.currentUser.delete();
          this.props.history.push('/');
        } catch (error) {
          if (error.code !== 'auth/requires-recent-login') {
            console.error(error);
            return messageAnt.error('Error');
          }
          const { providerData } = auth.currentUser;
          const { providerId } = providerData[0];

          let provider;
          if (providerId === 'google.com') {
            provider = new firebase.auth.GoogleAuthProvider();
          } else if (providerId === 'facebook.com') {
            provider = new firebase.auth.FacebookAuthProvider();
          } else if (providerId === 'twitter.com') {
            provider = new firebase.auth.TwitterAuthProvider();
          }

          await auth.currentUser.reauthenticateWithPopup(provider);
          await auth.currentUser.delete();
          this.props.history.push('/');
        }
      },
    });
  };

  handleChangeEmail = (email) => { };

  render() {
    return (
      <Row type="flex" align="middle" justify="center" style={{ marginTop: 24, marginBottom: 24 }}>
        <Col xs={24} md={20}>
          <Card title="Information">
            <Form layout="vertical">
              <Form.Item label={<span className="required">Plan</span>}>
                <Typography.Title level={4}>Standard</Typography.Title>
              </Form.Item>
              <Form.Item>
                <Divider />
              </Form.Item>
              <Form.Item label={<span className="required">Name</span>}>
                <Typography.Title level={4}>{this.props.displayName}</Typography.Title>
              </Form.Item>
              <Form.Item label={<span className="required">Email</span>}>
                <Typography.Title level={4}>{this.props.email}</Typography.Title>
              </Form.Item>
              <Form.Item>
                <Button
                  type="danger"
                  style={{ marginRight: 12 }}
                  onClick={this.handleDeleteAccount}
                >
                  Delete account
                </Button>
                <Button onClick={this.handleLogout}>Logout</Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  uid: state.user.uid,
  email: state.user.email,
  displayName: state.user.displayName,
});

export default withRouter(connect(mapStateToProps, { update })(ProfileForm));
