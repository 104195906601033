import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Button, Row, Col, Avatar, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  FacebookLoginButton,
  GoogleLoginButton,
  TwitterLoginButton,
  GithubLoginButton,
} from 'react-social-login-buttons';

import Modal from './styles/SigninModal.styles';
import HeaderStyles from './styles/Header.styles';
import { logo } from '../assets';
import { firebase } from '../utils/firebase';
import { updateSettings } from '../redux/settings/actions';

class Header extends React.PureComponent {
  handleAvatarClick = () => {
    if (this.props.uid) {
      this.props.history.push('/profile');
    } else {
      this.props.updateSettings({ isLoginModalVisible: true });
    }
  };

  handleGoogleSignin = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    this.handleSignin(provider);
  };

  handleFacebookSignin = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    this.handleSignin(provider);
  };

  handleTwitterSignin = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    this.handleSignin(provider);
  };

  handleGithubSignin = () => {
    const provider = new firebase.auth.GithubAuthProvider();
    this.handleSignin(provider);
  };

  handleSignin = async (provider) => {
    try {
      const auth = firebase.auth();
      await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      auth.useDeviceLanguage();
      const result = await auth.signInWithPopup(provider);
      const { metadata } = result.user;
      const creationTime = new moment(metadata.creationTime);
      const diff = new moment().diff(creationTime, 'minutes');
      this.props.updateSettings({ isLoginModalVisible: false });
      if (diff <= 1) {
        this.props.history.push('/profile?new=1');
      } else {
        this.props.history.push('/profile');
      }
    } catch (error) {
      message.error(error.message || 'Error');
    }
  };

  render() {
    return (
      <HeaderStyles>
        <Row type="flex" justify="center" align="middle">
          <Col xs={24} md={22} lg={20}>
            <div className="flex-div">
              <Link smooth to="/#home">
                <img src={logo} width="180" alt="GitBrand" />
              </Link>
              <div className="links-div">
                <Link smooth to="/#home">
                  home
                </Link>
                <Link smooth to="/#features">
                  features
                </Link>
                <Link smooth to="/#pricing">
                  pricing
                </Link>
                <Link smooth to="/#contact">
                  contact
                </Link>
                {this.props.uid ? (
                  <Avatar
                    icon={<UserOutlined />}
                    className="avatar"
                    src={this.props.photoURL}
                    onClick={this.handleAvatarClick}
                  />
                ) : (
                    <Button size="large" className="signin-btn" onClick={this.handleAvatarClick}>
                      sign in
                    </Button>
                  )}
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          width={600}
          footer={null}
          visible={this.props.isLoginModalVisible}
          onCancel={() => this.props.updateSettings({ isLoginModalVisible: false })}
        >
          <div className="section">
            <div className="section-in">
              <div className="title-section">
                <h1>Welcome</h1>
                <h3>
                  Sign in with your favorite social media to create and manage your repositories
                </h3>
              </div>
              <div className="signin-btns">
                <FacebookLoginButton onClick={this.handleFacebookSignin} />
                <GoogleLoginButton onClick={this.handleGoogleSignin} />
                <TwitterLoginButton onClick={this.handleTwitterSignin} />
                <GithubLoginButton onClick={this.handleGithubSignin} />
              </div>
              <div className="footer-section">
                <small>
                  We store user data and share it with service providers. By sign in in you accept
                  our <Link to="/privacy">Privacy Policy</Link>.
                </small>
              </div>
            </div>
          </div>
        </Modal>
      </HeaderStyles>
    );
  }
}

const mapStateToProps = (state) => ({
  uid: state.user.uid,
  photoURL: state.user.photoURL,
  isLoginModalVisible: state.settings.isLoginModalVisible,
});

export default withRouter(connect(mapStateToProps, { updateSettings })(Header));
