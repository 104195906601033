import React, { Component } from 'react';
import PropTypes from 'prop-types';
import tinycolor from 'tinycolor2';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Row, Col, Card, Divider, Table, Popover, message } from 'antd';

import ColorContainerStyles from './styles/ColorContainerStyles.styles';
import AddColor from './AddColor';
import List from './styles/List.styles';
import { firestore } from '../../utils/firebase';

class EditColors extends Component {
  handleRemoveColor = (color) => {
    Modal.confirm({
      title: 'Do you want to delete this color?',
      content: `Name: ${color.name}`,
      onOk: async () => {
        try {
          const colors = this.props.colors.filter((c) => c.id !== color.id);
          await firestore.doc(`repositories/${this.props.repoId}`).set({ colors }, { merge: true });
        } catch (error) {
          message.error(error.message || 'Error');
        }
      },
    });
  };
  render() {
    const colors = Object.values(this.props.colors);
    const columns = [
      {
        title: 'Color',
        key: 'color',
        dataIndex: 'color',
        render: (a, color) => {
          const colorView = (size) => <ColorContainerStyles size={size} background={color.hex} />;
          return <Popover content={colorView('large')}>{colorView('small')}</Popover>;
        },
      },
      {
        title: 'HEX',
        key: 'hex',
        dataIndex: 'hex',
      },
      {
        title: 'RGB',
        key: 'rgb',
        dataIndex: 'rgb',
        render: (rgb, color) => tinycolor(color.hex).toRgbString(),
      },
      {
        title: 'Actions',
        key: 'actions',
        dataIndex: 'actions',
        render: (a, color) => (
          <Button
            icon={<DeleteOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => this.handleRemoveColor(color)}
          />
        ),
      },
    ];
    return (
      <Row type="flex" justify="center" align="middle">
        <Col xs={22} md={16} lg={14}>
          <Card id="colors-container" style={{ width: '100%' }}>
            <div style={{ textAlign: 'center' }}>
              <AddColor colors={this.props.colors} repoId={this.props.repoId} />
            </div>
            <Divider />
            <List style={{ height: 'auto' }}>
              <Table
                bordered
                rowKey="id"
                size="small"
                columns={columns}
                style={{ width: '100%' }}
                dataSource={colors.sort((a, b) => a.sort - b.sort)}
                pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
              />
            </List>
          </Card>
        </Col>
      </Row>
    );
  }
}

EditColors.propTypes = {
  colors: PropTypes.array,
  repoId: PropTypes.string.isRequired,
};

EditColors.defaultProps = {
  colors: [],
};

export default EditColors;
