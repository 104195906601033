import { actions } from './actions';

const INITIAL_STATE = {
  repositories: [],
};

const repositoriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.UPDATE_REPOSITORIES:
      return { ...state, repositories: action.payload };
    default:
      return state;
  }
};

export default repositoriesReducer;
