import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import FontCard from './FontCard';

class Fonts extends Component {
  render() {
    const { fonts, repoName } = this.props;
    return (
      <Row type="flex" justify="center" align="middle">
        <Col xs={22} md={20} lg={18}>
          <Row type="flex" justify="center" align="middle" gutter={8}>
            {fonts
              .sort((a, b) => a.sort - b.sort)
              .map((font) => (
                <FontCard key={font.name} repoName={repoName} font={font} />
              ))}
          </Row>
        </Col>
      </Row>
    );
  }
}

Fonts.propTypes = {
  fonts: PropTypes.array,
  repoName: PropTypes.string.isRequired,
};

Fonts.defaultProps = {
  fonts: [],
};

export default Fonts;
