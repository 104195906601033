import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import LogoCard from './LogoCard';

class Logos extends Component {
  render() {
    const { logos, repoName } = this.props;
    return (
      <Row type="flex" justify="center" align="middle">
        <Col xs={22} md={20} lg={18}>
          <Row type="flex" justify="center" align="middle" gutter={8}>
            {logos
              .sort((a, b) => a.sort - b.sort)
              .map((logo) => (
                <LogoCard key={logo.name} repoName={repoName} logo={logo} />
              ))}
          </Row>
        </Col>
      </Row>
    );
  }
}

Logos.propTypes = {
  logos: PropTypes.array,
  repoName: PropTypes.string.isRequired,
};

Logos.defaultProps = {
  logos: [],
};

export default Logos;
