import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import ColorCard from './ColorCard';

class Colors extends Component {
  // handleMenuClick = ({ key }) => {
  //   console.log(key);
  // };
  render() {
    // const menu = (
    //   <Menu onClick={this.handleMenuClick}>
    //     <Menu.Item key="JSON">JSON</Menu.Item>
    //     <Menu.Item key="CSS">CSS</Menu.Item>
    //     <Menu.Item key="SCSS">SCSS</Menu.Item>
    //     <Menu.Item key="SASS">SASS</Menu.Item>
    //     <Menu.Item key="LESS">LESS</Menu.Item>
    //     <Menu.Divider />
    //     <Menu.Item key="*">ALL</Menu.Item>
    //   </Menu>
    // );
    const { colors } = this.props;
    return (
      <Row type="flex" justify="center" align="middle">
        {/*<Col style={{ textAlign: "center" }} xs={24}>
          <Dropdown overlay={menu}>
            <Button>
              Download <Icon type="down" />
            </Button>
          </Dropdown>
        </Col>*/}
        <Col xs={22} md={20} lg={18}>
          <Row type="flex" justify="center" align="middle" gutter={8}>
            {colors
              .sort((a, b) => a.sort - b.sort)
              .map((color) => (
                <ColorCard key={color.name} color={color} />
              ))}
          </Row>
        </Col>
      </Row>
    );
  }
}

Colors.propTypes = {
  colors: PropTypes.array,
};

Colors.defaultProps = {
  colors: [],
};

export default Colors;
