const actions = {
  UPDATE_REPOSITORIES: 'UPDATE_REPOSITORIES',
};

const updateRepositories = (updates) => ({
  type: actions.UPDATE_REPOSITORIES,
  payload: updates,
});

export { actions, updateRepositories };
