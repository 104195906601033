const defaultLogos = [
  {
    type: 'logo-standard',
    name: 'Logo Standard',
  },
  {
    type: 'logo-black',
    name: 'Logo Black',
  },
  {
    type: 'logo-white',
    name: 'Logo White',
  },
  {
    type: 'icon-standard',
    name: 'Icon Standard',
  },
  {
    type: 'icon-black',
    name: 'Icon Black',
  },
  {
    type: 'icon-white',
    name: 'Icon White',
  },
];

const defaultColors = [
  {
    type: 'primary',
    name: 'Primary',
  },
  {
    type: 'secondary',
    name: 'Secondary',
  },
  {
    type: 'success',
    name: 'Success',
  },
  {
    type: 'info',
    name: 'Info',
  },
  {
    type: 'warning',
    name: 'Warning',
  },
  {
    type: 'danger',
    name: 'Danger',
  },
  {
    type: 'light',
    name: 'Light',
  },
  {
    type: 'dark',
    name: 'Dark',
  },
  {
    type: 'muted',
    name: 'Muted',
  },
  {
    type: 'white',
    name: 'White',
  },
];

const defaultFonts = [
  {
    type: 'heading-1',
    name: 'Heading 1',
  },
  {
    type: 'heading-2',
    name: 'Heading 2',
  },
  {
    type: 'heading-3',
    name: 'Heading 3',
  },
  {
    type: 'heading-4',
    name: 'Heading 4',
  },
  {
    type: 'title',
    name: 'Title',
  },
  {
    type: 'subtitle',
    name: 'Subtitle',
  },
  {
    type: 'body',
    name: 'Body',
  },
];

const defaultResources = [
  {
    name: 'Brand guideline',
    type: 'branding',
    ext: 'pdf',
  },
  {
    name: 'Wallpaper',
    type: 'wallpaper',
    ext: 'jpg',
  },
  {
    name: 'Background',
    type: 'background',
    ext: 'jpg',
  },
];

export { defaultResources, defaultLogos, defaultColors, defaultFonts };
