import styled from 'styled-components';

const ColorCardStyles = styled.div`
  display: flex;
  height: 92px;
  text-align: center;
  overflow-y: scroll;

  .container {
    width: 80px;
    height: 80px;
    padding: 4px;
    margin: 6px;
    border: 1px solid #eee;
    border-radius: 4px;

    img {
      width: 100%;
      height: 100%;
    }
    .color-container {
      width: 70px;
      height: 70px;
      border-radius: 2px;
    }
    .font {
      font-size: 46px;
      line-height: 70px;
    }
  }
`;

export default ColorCardStyles;
