import { actions } from './actions';

const INITIAL_STATE = {
  isLoginModalVisible: false,
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.UPDATE_SETTINGS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default settingsReducer;
