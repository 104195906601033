import { actions } from './actions';

const INITIAL_STATE = {
  uid: '',
  displayName: '',
  email: '',
  photoURL: '',
  creationTime: '',
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.LOG_IN:
      return { ...state, ...action.payload };
    case actions.UPDATE:
      return { ...state, ...action.payload };
    case actions.LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default userReducer;
