import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Modal, Tag, Row, Col, Card, Divider, Table, message } from 'antd';

import AddResource from './AddResource';
import List from './styles/List.styles';
import { getExtensionColor } from '../../utils/helpers';
import { firestore } from '../../utils/firebase';

class EditResources extends Component {
  handleRemoveResource = (resource) => {
    Modal.confirm({
      title: 'Do you want to delete this resource?',
      content: `Name: ${resource.name}`,
      onOk: async () => {
        try {
          const resources = this.props.resources.filter((r) => r.id !== resource.id);
          await firestore
            .doc(`repositories/${this.props.repoId}`)
            .set({ resources }, { merge: true });
        } catch (error) {
          message.error(error.message || 'Error');
        }
      },
    });
  };
  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'File Type',
        key: 'ext',
        dataIndex: 'ext',
        render: (ext) => <Tag color={getExtensionColor(ext)}>{(ext || '').toUpperCase()}</Tag>,
      },
      {
        title: 'Actions',
        key: 'actions',
        dataIndex: 'actions',
        render: (a, r) => (
          <>
            <Button
              icon={<DeleteOutlined />}
              style={{ marginRight: 8 }}
              onClick={() => this.handleRemoveResource(r)}
            />
            <a href={r.url} target="_blank" rel="noreferrer noopener">
              <Button icon={<LinkOutlined />} />
            </a>
          </>
        ),
      },
    ];
    const resources = Object.values(this.props.resources).sort((a, b) => a.sort - b.sort);
    return (
      <Row type="flex" justify="center" align="middle">
        <Col xs={22} md={16} lg={14}>
          <Card id="resources-container" style={{ width: '100%' }}>
            <div style={{ textAlign: 'center' }}>
              <AddResource repoId={this.props.repoId} resources={this.props.resources} />
            </div>
            <Divider />
            <List style={{ height: 'auto' }}>
              <Table
                bordered
                size="small"
                rowKey="id"
                columns={columns}
                pagination={false}
                dataSource={resources}
                style={{ width: '100%' }}
              />
            </List>
          </Card>
        </Col>
      </Row>
    );
  }
}

EditResources.propTypes = {
  repoId: PropTypes.string.isRequired,
  resources: PropTypes.array,
};

EditResources.defaultProps = {
  resources: [],
};

export default EditResources;
