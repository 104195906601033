import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Divider, Button, Modal, Row, Col, Card, Table, message } from 'antd';

import AddFAQ from './AddFAQ';
import List from './styles/List.styles';
import { firestore } from '../../utils/firebase';

class EditFAQ extends Component {
  state = {
    editQuestionId: null,
  };

  handleRemoveQuestion = (question) => {
    Modal.confirm({
      title: 'Do you want to delete this question?',
      content: question.question,
      onOk: async () => {
        try {
          const faq = this.props.faq.filter((q) => q.id !== question.id);
          await firestore.doc(`repositories/${this.props.repoId}`).set({ faq }, { merge: true });
        } catch (error) {
          message.error(error.message || 'Error');
        }
      },
    });
  };

  handleEditQuestion = (editQuestionId) => {
    this.setState({ editQuestionId });
  };

  render() {
    const faq = Object.values(this.props.faq);
    const columns = [
      {
        title: 'Question',
        key: 'question',
        dataIndex: 'question',
      },
      {
        title: 'Answer',
        key: 'answer',
        dataIndex: 'answer',
      },
      {
        title: 'Actions',
        key: 'actions',
        dataIndex: 'actions',
        render: (a, question) => (
          <>
            <Button
              icon={<EditOutlined />}
              style={{ marginRight: 8 }}
              onClick={() => this.handleEditQuestion(question.id)}
            />
            <Button
              icon={<DeleteOutlined />}
              style={{ marginRight: 8 }}
              onClick={() => this.handleRemoveQuestion(question)}
            />
          </>
        ),
      },
    ];
    return (
      <Row type="flex" justify="center" align="middle">
        <Col xs={22} md={16} lg={14}>
          <Card id="faq-container" style={{ width: '100%' }}>
            <div style={{ textAlign: 'center' }}>
              <AddFAQ
                faq={this.props.faq}
                repoId={this.props.repoId}
                editQuestionId={this.state.editQuestionId}
                handleEditQuestion={this.handleEditQuestion}
              />
            </div>
            <Divider />
            <List style={{ height: 'auto' }}>
              <Table
                bordered
                rowKey="id"
                size="small"
                columns={columns}
                style={{ width: '100%' }}
                dataSource={faq.sort((a, b) => a.sort - b.sort)}
                pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
              />
            </List>
          </Card>
        </Col>
      </Row>
    );
  }
}

EditFAQ.propTypes = {
  faq: PropTypes.array,
  repoId: PropTypes.string.isRequired,
};

EditFAQ.defaultProps = {
  faq: [],
};

export default EditFAQ;
