export default [
  {
    content: 'Click here to view your repository',
    disableBeacon: true,
    placement: 'top',
    target: '#view-repository-button',
    title: 'View Repository',
  },
  {
    content: 'Click here to open your repository settings',
    placement: 'top',
    target: '#open-setting-button',
    title: 'Repository Settings',
  },
  {
    content: 'Upload logos, icons and others SVG resources related to your brand',
    placement: 'top',
    target: '#logos-container',
    title: 'Logos',
  },
  {
    content: 'Add your brand colors, so everyone can follow your standards',
    placement: 'top',
    target: '#colors-container',
    title: 'Colors',
  },
  {
    content: 'Upload your headings and body fonts',
    placement: 'top',
    target: '#fonts-container',
    title: 'Fonts',
  },
  {
    content: 'Feel free to upload any other resource about your brand here',
    placement: 'top',
    target: '#resources-container',
    title: 'Resources',
  },
  {
    content: 'Finally, add some Q&A about your branding',
    placement: 'top',
    target: '#faq-container',
    title: 'FAQ',
  },
];
