import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col } from 'antd';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';

import SubFooterStyles from './styles/SubFooter.styles';
import { updateSettings } from '../../redux/settings/actions';
import { analytics } from '../../utils/firebase';

class SubFooter extends Component {
  handleStart = () => {
    analytics.logEvent('sub_footer_signin');
    if (!!this.props.uid) {
      this.props.history.push('/profile');
    } else {
      this.props.updateSettings({ isLoginModalVisible: true });
    }
  };

  beforeOnClick = (name) => {
    analytics.logEvent('sub_footer_share', { name });
  };

  render() {
    const url = `https://gitbrand.com${this.props.location.pathname}`;
    return (
      <SubFooterStyles type="flex" justify="center" align="middle">
        <Col xs={22} md={10} lg={6} style={{ textAlign: 'center' }}>
          <div className="share-btns">
            <FacebookShareButton url={url} beforeOnClick={() => this.beforeOnClick('facebook')}>
              <FacebookIcon size={32} borderRadius={6} iconbgstyle={{ fill: '#252525' }} />
            </FacebookShareButton>
            <LinkedinShareButton url={url} beforeOnClick={() => this.beforeOnClick('linkedin')}>
              <LinkedinIcon size={32} borderRadius={6} iconbgstyle={{ fill: '#252525' }} />
            </LinkedinShareButton>
            <TwitterShareButton
              url={url}
              hashtags={['GitBrand']}
              beforeOnClick={() => this.beforeOnClick('twitter')}
            >
              <TwitterIcon size={32} borderRadius={6} iconbgstyle={{ fill: '#252525' }} />
            </TwitterShareButton>
            <WhatsappShareButton url={url} beforeOnClick={() => this.beforeOnClick('whatsapp')}>
              <WhatsappIcon size={32} borderRadius={6} iconbgstyle={{ fill: '#252525' }} />
            </WhatsappShareButton>
            <EmailShareButton
              url={url}
              body="Hello, here it is the link"
              subject={`Take a look at ${this.props.name}'s brand`}
              beforeOnClick={() => this.beforeOnClick('email')}
            >
              <EmailIcon size={32} borderRadius={6} iconbgstyle={{ fill: '#252525' }} />
            </EmailShareButton>
          </div>
        </Col>
        {/*
          <Col
          xs={22}
          md={18}
          lg={14}
          style={{ textAlign: "center", marginTop: 62 }}
          >
          <div className="cta-container">
            <h4 className="cta">
              Did you like it? Feel free to add your repository assets
            </h4>
            <Button size="large" onClick={this.handleStart}>
              Add your repository
            </Button>
          </div>
          </Col>
          */}
      </SubFooterStyles>
    );
  }
}

const mapStateToProps = (state) => ({
  uid: state.user.uid,
});

export default withRouter(connect(mapStateToProps, { updateSettings })(SubFooter));
