import styled from 'styled-components';

const RepoTitleStyles = styled.div`
  .repo-name {
    font-size: 32px;
    margin-bottom: 0;
    margin-top: 24px;
    display: flex;
    align-items: center;
    max-width: 80%;
    .ant-tag {
      margin-left: 8px;
      margin-right: 0;
    }
  }
  .repo-updated {
    color: #bbb;
    font-size: 14px;
    font-weight: 400;
  }
  .anticon-flag,
  .anticon-edit,
  .anticon-eye {
    color: #ddd;
    font-size: 22px;
    margin-left: 8px;
    cursor: pointer;
  }
  .ant-divider {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .container-divider {
    width: 40%;
  }
  @media only screen and (max-width: 40em) {
    .container-divider {
      width: 80%;
    }
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .extra {
    margin-left: 12px;
    text-align: right;
  }
`;
export default RepoTitleStyles;
