import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Joyride from 'react-joyride';
import { Helmet } from 'react-helmet';
import pathOr from 'ramda/src/pathOr';

import joyrideSteps from './helpers/joyrideSteps';
import { RepoTitle, Title, Loading } from '../../components';
import { firestore } from '../../utils/firebase';
import EditLogos from './EditLogos';
import EditColors from './EditColors';
import EditFonts from './EditFonts';
import EditResources from './EditResources';
import EditFAQ from './EditFAQ';
import Settings from './Settings';

class EditRepo extends Component {
  state = {
    new: false,
    isLoading: true,
    runJoyride: false,
  };

  componentDidMount() {
    const repoId = pathOr(null, ['match', 'params', 'id'], this.props);
    this.getDocument(repoId);
    if (!!repoId && this.props.location.search === '?new=1') {
      this.props.history.replace(`/edit/${repoId}`);
      this.setState({ new: true });
    }
  }

  componentWillUnmount() {
    try {
      this.unsub();
    } catch (e) {}
  }

  joyrideCallback = (data) => {
    if (data.action === 'update') {
      const { target } = data.step;
      document.location.href = target;
    }
  };

  getDocument = (repoId) => {
    if (!repoId) {
      return this.props.history.push('/');
    }
    this.unsub = firestore.doc(`repositories/${repoId}`).onSnapshot(
      (doc) => {
        if (!doc.exists) {
          return this.props.history.push('/');
        }

        const data = doc.data();
        if ((data.members || []).indexOf(this.props.uid) === -1) {
          this.props.history.push(`/${repoId}`);
        } else {
          this.setState((prevState) => ({
            ...data,
            repoId,
            isLoading: false,
            runJoyride: prevState.new,
          }));
        }
      },
      (error) => {
        this.props.history.push(`/${repoId}`);
      },
    );
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    const pageTitle = `${this.state.name} - GitBrand`;

    return (
      <div style={{ marginBottom: 36 }}>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={this.state.description} />
        </Helmet>
        <Joyride
          continuous={true}
          steps={joyrideSteps}
          showSkipButton={true}
          scrollToFirstStep={true}
          run={this.state.runJoyride}
          callback={this.joyrideCallback}
          styles={{
            options: { primaryColor: '#252525', textColor: '#252525' },
          }}
        />
        <RepoTitle
          url={this.state.url}
          name={this.state.name}
          repoId={this.state.repoId}
          members={this.state.members}
          isPublic={this.state.isPublic}
          description={this.state.description}
        />
        <Settings
          url={this.state.url}
          name={this.state.name}
          repoId={this.state.repoId}
          isPublic={this.state.isPublic}
          password={this.state.password}
          description={this.state.description}
        />
        <Title text="logos" />
        <EditLogos logos={this.state.logos} repoId={this.state.repoId} />

        <Title text="colors" style={{ marginTop: 60 }} />
        <EditColors colors={this.state.colors} repoId={this.state.repoId} />

        <Title text="fonts" style={{ marginTop: 60 }} />
        <EditFonts fonts={this.state.fonts} repoId={this.state.repoId} />

        <Title text="resources" style={{ marginTop: 60 }} />
        <EditResources repoId={this.state.repoId} resources={this.state.resources} />

        <Title text="faq" style={{ marginTop: 60 }} />
        <EditFAQ faq={this.state.faq} repoId={this.state.repoId} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  uid: state.user.uid,
});

export default withRouter(connect(mapStateToProps)(EditRepo));
