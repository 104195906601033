import styled from 'styled-components';

const LogoContainerStyles = styled.div`
  width: ${({ size }) => (size === 'large' ? '200px' : '80px')};
  height: ${({ size }) => (size === 'large' ? '200px' : '40px')};
  padding: ${({ size }) => (size === 'large' ? '12px' : '4px')};
  border: 1px solid #eee;
  border-radius: 4px;
  background: ${({ darkbackground }) => (darkbackground ? '#252525' : '#fff')};
  img {
    width: 100%;
    height: 100%;
  }
`;

export default LogoContainerStyles;
