import styled from 'styled-components';

const ProfileStyles = styled.div`
  margin-top: 32px;
  .profile-title {
    font-size: 32px;
    margin-bottom: 0;
  }
  .profile-created-at {
    color: #bbb;
    font-size: 14px;
    font-weight: 400;
  }
  .ant-divider {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .container-divider {
    width: 40%;
  }
  @media only screen and (max-width: 40em) {
    .container-divider {
      width: 80%;
    }
  }
  .repo-card .ant-card-body {
    background: #ffffff;
    background-image: linear-gradient(to right, #fafafa 1px, transparent 1px),
      linear-gradient(#fafafa 1px, transparent 1px);
    background-size: 14px 14px;
    border: 1px solid #e8e8e8;
    margin: -1px;
  }
`;
export default ProfileStyles;
