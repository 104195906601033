const isValidEmail = (email) => {
  const tester = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  if (!email) return false;

  if (email.length > 254) return false;

  var valid = tester.test(email);

  if (!valid) return false;

  // Further checking of some things regex can't handle
  var parts = email.split('@');
  if (parts[0].length > 64) return false;

  var domainParts = parts[1].split('.');
  if (domainParts.some((part) => part.length > 63)) return false;

  return true;
};

const isValidURL = (url) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(url);
};

const cFirstLetter = (str) => {
  if (!str) return '';
  return str[0].toUpperCase() + str.slice(1);
};

const getExtensionColor = (e) => {
  const ext = (e || '').toLowerCase();
  switch (ext) {
    case 'pdf':
      return 'magenta';
    case 'zip':
      return 'volcano';
    case 'png':
      return 'green';
    case 'jpg':
    case 'jpeg':
      return 'geekblue';
    case 'docx':
    case 'doc':
      return 'gold';
    case 'xls':
    case 'xlsx':
      return 'lime';
    case 'ppt':
    case 'pptx':
      return 'cyan';
    case 'txt':
      return 'purple';
    case 'htm':
    case 'html':
      return 'orange';
    default:
      return '#252525';
  }
};

const generateId = (name) => {
  return (name || '')
    .trim()
    .replace(/\s/g, '-')
    .replace(/[^a-zA-Z0-9-]/g, '')
    .toLowerCase();
};

const addhttp = (url) => {
  return !/^(?:f|ht)tps?:\/\//.test(url) ? 'http://' + url : url;
};

const typeExists = (arr, type) => {
  return arr.some((el) => el.type === type);
};

export {
  generateId,
  getExtensionColor,
  cFirstLetter,
  isValidURL,
  isValidEmail,
  addhttp,
  typeExists,
};
