export default [
  {
    title: 'Font Family',
    dataIndex: 'fontFamily',
    key: 'fontFamily',
  },
  {
    title: 'Sub Family',
    dataIndex: 'fontSubfamily',
    key: 'fontSubfamily',
  },
  {
    title: 'Designer',
    dataIndex: 'designer',
    key: 'designer',
  },
];
