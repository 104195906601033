import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const LoadingStyles = styled.div`
  display: flex;
  margin: 80px 12px;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
`;

const Loading = () => {
  return (
    <LoadingStyles>
      <LoadingOutlined style={{ fontSize: 58 }} spin />
    </LoadingStyles>
  );
};

export default Loading;
