import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';
import { Modal, Popover, Button, Table, Row, Col, Card, Divider, message } from 'antd';

import FontContainerStyles from './styles/FontContainerStyles.styles';
import AddFont from './AddFont';
import List from './styles/List.styles';
import { firestore } from '../../utils/firebase';

class EditFonts extends Component {
  handleRemoveFont = (font) => {
    Modal.confirm({
      title: 'Do you want to delete this font?',
      content: `Name: ${font.name}`,
      onOk: async () => {
        try {
          const fonts = this.props.fonts.filter((f) => f.id !== font.id);
          await firestore.doc(`repositories/${this.props.repoId}`).set({ fonts }, { merge: true });
        } catch (error) {
          message.error(error.message || 'Error');
        }
      },
    });
  };

  render() {
    const fonts = Object.values(this.props.fonts);
    const columns = [
      {
        title: 'Font',
        key: 'font',
        dataIndex: 'font',
        render: (a, font) => {
          const colorView = (type) => (
            <FontContainerStyles
              className="font"
              type={type}
              fonturl={font.url}
              fontname={font.postScriptName}
            >
              {type === 'table' ? (
                <span>A..z</span>
              ) : (
                <div>
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ <br />
                  abcdefghijklmnopqrstuvwxyz <br />
                  0123456789
                </div>
              )}
            </FontContainerStyles>
          );
          return <Popover content={colorView('popover')}>{colorView('table')}</Popover>;
        },
      },
      {
        title: 'Type',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: 'Font Family',
        key: 'fontFamily',
        dataIndex: 'fontFamily',
      },
      {
        title: 'Sub Family',
        key: 'fontSubfamily',
        dataIndex: 'fontSubfamily',
      },
      {
        title: 'Actions',
        key: 'actions',
        dataIndex: 'actions',
        render: (a, font) => (
          <Button
            icon={<DeleteOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => this.handleRemoveFont(font)}
          />
        ),
      },
    ];
    return (
      <Row type="flex" justify="center" align="middle">
        <Col xs={22} md={16} lg={14}>
          <Card id="fonts-container" style={{ width: '100%' }}>
            <div style={{ textAlign: 'center' }}>
              <AddFont fonts={this.props.fonts} repoId={this.props.repoId} />
            </div>
            <Divider />
            <List style={{ height: 'auto' }}>
              <Table
                bordered
                rowKey="id"
                size="small"
                columns={columns}
                style={{ width: '100%' }}
                dataSource={fonts.sort((a, b) => a.sort - b.sort)}
                pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
              />
            </List>
          </Card>
        </Col>
      </Row>
    );
  }
}

EditFonts.propTypes = {
  fonts: PropTypes.array,
  repoId: PropTypes.string.isRequired,
};

EditFonts.defaultProps = {
  fonts: [],
};

export default EditFonts;
