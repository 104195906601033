import '@ant-design/compatible/assets/index.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Button, message } from 'antd';
import shortId from 'short-id';

import Modal from './styles/Modal.styles';
import { Spin } from '../../components';
import { firestore, FieldValue } from '../../utils/firebase';

const initialState = {
  isModalVisible: false,
  question: '',
  answer: '',
  isLoading: false,
  id: null,
};

class AddFAQ extends Component {
  state = initialState;

  componentWillReceiveProps(nextProps) {
    const { editQuestionId: oldEditQuestionId } = this.props;
    const { faq, editQuestionId } = nextProps;
    if (!oldEditQuestionId && oldEditQuestionId !== editQuestionId) {
      const editFaq = faq.filter((f) => f.id === editQuestionId)[0];
      this.setState({
        isModalVisible: true,
        question: editFaq.question,
        answer: editFaq.answer,
        id: editQuestionId,
      });
    }
  }

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  hideModal = (force) => {
    if (!force && this.state.isLoading) return;
    this.setState({ ...initialState });
    this.props.handleEditQuestion(null);
  };

  handleChangeType = (type) => {
    this.setState({ type });
  };

  handleSubmit = async () => {
    const { question, answer, id } = this.state;
    const { repoId } = this.props;
    let { faq } = this.props;

    const sort = faq.length + 1;

    if (question.length < 3) {
      return message.error('Please provide a valid question');
    }

    if (answer.length < 3) {
      return message.error('Please provide a valid answer');
    }

    try {
      this.setState({ isLoading: true });

      if (id) {
        faq = faq.map((f) => (f.id === id ? { question, answer, sort, id } : f));
      } else {
        faq.push({ question, answer, sort, id: shortId.generate() });
      }

      const newValue = {
        faq,
        updatedAt: FieldValue.serverTimestamp(),
      };
      const merge = { merge: true };
      await firestore.doc(`repositories/${repoId}`).set(newValue, merge);
      this.hideModal(true);
    } catch (error) {
      message.error(error.message || 'Error');
      this.setState({ isLoading: false });
    }
  };

  handleChangeInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <>
        <Button icon={<PlusOutlined />} size="large" type="primary" onClick={this.showModal}>
          Add question
        </Button>
        <Modal
          okText={this.state.id ? 'Save' : 'Add'}
          title="Add question to FAQ"
          style={{ top: 20 }}
          onOk={this.handleSubmit}
          onCancel={this.hideModal}
          visible={this.state.isModalVisible}
        >
          <Spin spinning={this.state.isLoading} style={{ textAlign: 'center' }}>
            <Form layout="vertical">
              <Form.Item label={<span className="required">Question</span>}>
                <Input
                  autoFocus
                  size="large"
                  name="question"
                  autoComplete="off"
                  placeholder="E.g.: Where can I use it?"
                  value={this.state.question}
                  disabled={this.state.loading}
                  onChange={this.handleChangeInput}
                  prefix={<QuestionCircleOutlined />}
                />
              </Form.Item>
              <Form.Item label={<span className="required">Answer</span>}>
                <Input.TextArea
                  size="large"
                  name="answer"
                  autoComplete="off"
                  placeholder="E.g.: Everywhere."
                  value={this.state.answer}
                  disabled={this.state.loading}
                  onChange={this.handleChangeInput}
                  autoSize={{ minRows: 4, maxRows: 10 }}
                />
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </>
    );
  }
}

AddFAQ.propTypes = {
  faq: PropTypes.array,
  repoId: PropTypes.string.isRequired,
  editQuestionId: PropTypes.string,
  handleEditQuestion: PropTypes.func,
};

AddFAQ.defaultProps = {
  faq: [],
  editQuestionId: null,
  handleEditQuestion: () => null,
};

export default AddFAQ;
