import styled from 'styled-components';
import { Card } from 'antd';

const ColorCardStyles = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
  .ant-typography {
    font-size: 14px;
  }
  .color-background {
    border: 1px solid #e8e8e8;
    margin: 0px -1px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    .anticon-copy {
      color: ${(props) => props.color};
    }
  }
  .m-t-6 {
    margin-top: 6px;
  }
`;

export default ColorCardStyles;
