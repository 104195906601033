import styled from 'styled-components';
import { Card } from 'antd';

const LogoCardStyles = styled(Card)`
  .anticon-download {
    font-size: 18px;
    cursor: pointer;
  }
  .card-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ant-card-body {
    ${({ darkbackground }) =>
      darkbackground === 1
        ? 'background: #252525; background-image: linear-gradient(to right, #2a2a2a 1px, transparent 1px),linear-gradient(#2a2a2a 1px, transparent 1px);'
        : 'background: #ffffff; background-image: linear-gradient(to right, #fafafa 1px, transparent 1px),linear-gradient(#fafafa 1px, transparent 1px);'};
    background-size: 14px 14px;
    border: 1px solid #e8e8e8;
    margin: -1px;
  }
`;

export default LogoCardStyles;
