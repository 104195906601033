import styled from 'styled-components';
import { Row } from 'antd';

const SubFooterStyles = styled(Row)`
  .share-btns {
    display: flex;
    margin-top: 12px;
    align-items: center;
    justify-content: space-around;

    div {
      margin: 0 4px;
      cursor: pointer;
    }
  }

  .cta {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 24px;
    color: #fff;
  }

  .cta-container {
    background: #252525;
    padding: 36px 0;
    color: #fff;
    border-radius: 4px;
  }
`;

export default SubFooterStyles;
