import '@ant-design/compatible/assets/index.css';
import React, { Component } from 'react';
import { HomeOutlined, LoadingOutlined, LockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Modal, Button, Row, Col, Checkbox, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import equals from 'ramda/src/equals';

import { generateId } from '../../utils/helpers';
import { updateRepositories } from '../../redux/repositories/actions';
import { auth, firebase, firestore, functions } from '../../utils/firebase';
import RepoCard from './RepoCard';

class Repositories extends Component {
  state = {
    loading: false,
    loadingNewRepo: false,
    isCreateRepoModalVisible: false,
    newRepoName: '',
    newRepoId: '',
    isPublic: true,
    password: '',
  };

  componentDidMount() {
    this.getRepositories();
  }

  componentWillUnmount() {
    try {
      this.stopRepositories();
    } catch (e) { }
  }

  handleLogout = async () => {
    try {
      await auth.signOut();
      this.props.history.push('/');
    } catch (error) {
      message.error(error.message || 'Error');
    }
  };

  getRepositories = async () => {
    try {
      this.stopRepositories = await firestore
        .collection('repositories')
        .where('members', 'array-contains', this.props.uid)
        .onSnapshot(
          (querySnapshot) => {
            const repositories = [];
            querySnapshot.forEach((doc) => {
              repositories.push(doc.data());
            });
            if (!equals(repositories, this.props.repositories)) {
              this.props.updateRepositories(repositories);
            }
          },
          (error) => {
            if (error.code === 'permission-denied') {
              this.handleLogout();
            } else {
              message.error(error.message || 'Error');
            }
          },
        );
    } catch (error) {
      message.error(error.message || 'Error');
      this.setState({ loading: false });
    }
  };

  handleCreateRepo = async () => {
    try {
      const { newRepoName, newRepoId, isPublic, password } = this.state;

      if (!newRepoName || newRepoName.length < 3) {
        return message.error('The name of the repository needs to be at least 3 characters long');
      }

      if (
        [
          'repo',
          'repository',
          'profile',
          'privacy',
          'search',
          'reps',
          'public',
          'edit',
          'list',
          'invite',
          'link',
          'embed',
        ].includes(newRepoId)
      ) {
        return message.error('This is an invalid repository name');
      }

      this.setState({ loadingNewRepo: true });
      const checkRepo = functions.httpsCallable('checkRepo');
      const result = await checkRepo({ id: newRepoId });
      if (result.data.exists) {
        this.setState({ loadingNewRepo: false });
        return message.error('Repository name already used');
      }

      const repo = {
        id: newRepoId,
        isPublic,
        description: '',
        name: newRepoName.trim(),
        members: [this.props.uid],
        createdBy: this.props.uid,
        password: password || null,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await firestore.doc(`repositories/${newRepoId}`).set(repo);
      this.props.history.push(`/edit/${newRepoId}?new=1`);
    } catch (error) {
      this.setState({ loadingNewRepo: false });
      message.error(error.message || 'Error');
    }
  };

  handleShowCreateRepoModal = () => {
    this.setState({ isCreateRepoModalVisible: true });
  };

  handleCancelCreateRepo = () => {
    this.setState({
      isCreateRepoModalVisible: false,
      newRepoName: '',
      newRepoId: '',
      loadingNewRepo: false,
      isPublic: true,
      password: '',
    });
  };

  handleChangeRepoName = (e) => {
    const newRepoName = e.target.value;
    const newRepoId = generateId(newRepoName);
    this.setState({ newRepoName, newRepoId });
  };

  handleChangePrivate = (e) => {
    this.setState({ isPublic: !e.target.checked });
    if (!e.target.checked) {
      this.setState({ password: '' });
    }
  };

  render() {
    const { repositories } = this.props;
    return (
      <>
        <Row
          type="flex"
          align="middle"
          justify="center"
          style={{ marginTop: 24, marginBottom: 48 }}
        >
          <Col xs={24}>
            {this.state.loading ? (
              <div style={{ textAlign: 'center' }}>
                <LoadingOutlined style={{ fontSize: 36 }} spin />
              </div>
            ) : (
                <>
                  <div style={{ textAlign: 'center', marginBottom: 36 }}>
                    <Button
                      size="large"
                      type="primary"
                      id="new-repository-button"
                      onClick={this.handleShowCreateRepoModal}
                    >
                      {repositories.length > 0
                        ? 'Create a new repository'
                        : 'Create your first repository'}
                    </Button>
                  </div>
                  <Row type="flex" justify="start" align="middle" gutter={12}>
                    {repositories.map((repo) => (
                      <RepoCard key={repo.id} repo={repo} />
                    ))}
                  </Row>
                </>
              )}
          </Col>
        </Row>
        <Modal
          okText="Create"
          title="Create a new repository"
          onOk={this.handleCreateRepo}
          onCancel={this.handleCancelCreateRepo}
          confirmLoading={this.state.loadingNewRepo}
          visible={this.state.isCreateRepoModalVisible}
        >
          <Form layout="vertical">
            <Form.Item
              extra={(
                <span style={{ fontSize: 12 }}>
                  https://gitbrand.com/
                  <strong>{this.state.newRepoId}</strong>
                </span>
              )}
              label={<span className="required">Repository name</span>}
            >
              <Input
                autoFocus
                size="large"
                name="newRepoName"
                autoComplete="off"
                placeholder="E.g.: Apple"
                disabled={this.state.loadingNewRepo}
                value={this.state.newRepoName}
                onChange={this.handleChangeRepoName}
                prefix={<HomeOutlined />}
              />
            </Form.Item>
            <Form.Item
              extra={
                !this.state.isPublic && <small>Leave in blank to keep it completely private</small>
              }
              label={(
                <Checkbox
                  checked={!this.state.isPublic}
                  onChange={this.handleChangePrivate}
                  disabled={this.state.loadingNewRepo}
                >
                  Private
                </Checkbox>
              )}
            >
              <Input.Password
                size="large"
                name="password"
                autoComplete="off"
                placeholder="Password"
                value={this.state.password}
                disabled={this.state.isPublic || this.state.loadingNewRepo}
                onChange={(e) => this.setState({ password: e.target.value })}
                prefix={<LockOutlined />}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  uid: state.user.uid,
  repositories: state.repositories.repositories,
});

export default withRouter(connect(mapStateToProps, { updateRepositories })(Repositories));
