import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { UpOutlined } from '@ant-design/icons';
import { BackTop, Button, Card, Row, Col } from 'antd';

import { Title } from '../../components';
import HomeStyles from './Home.styles';
import Contact from './Contact';
import { background, card1, card2, card3 } from '../../assets';
import { updateSettings } from '../../redux/settings/actions';

class Home extends Component {
  handleStart = () => {
    if (!!this.props.uid) {
      this.props.history.push('/profile');
    } else {
      this.props.updateSettings({ isLoginModalVisible: true });
    }
  };

  render() {
    return (
      <HomeStyles>
        <Helmet>
          <title>Home - GitBrand</title>
          <meta name="description" content="Free repository for your brand assets" />
        </Helmet>
        <BackTop>
          <div className="top-inner">
            <UpOutlined />
          </div>
        </BackTop>
        <Row
          type="flex"
          align="middle"
          justify="center"
          className="content"
          style={{ backgroundImage: `url(${background})` }}
        >
          <Col xs={24} md={22} lg={20}>
            <h1 className="title">Free repository for your brand assets</h1>
            <Button size="large" type="primary" className="start-btn" onClick={this.handleStart}>
              Start now
            </Button>
          </Col>
        </Row>
        <Row type="flex" justify="center" align="middle">
          <Col xs={24} md={22} lg={20} style={{ textAlign: 'center' }}>
            <h2 className="trusted-by">trusted by</h2>
            <div className="trusted-logos">
              <a href="https://gitbrand.com/w3lcome">
                <img width="120" alt="w3lcome" height="auto" src="./images/w3lcome.png" />
              </a>
            </div>
          </Col>
        </Row>
        <Title
          text="features"
          subtitle="Here are our main features, but there is to much more, trust me ;) try it out"
        />
        <Row className="section" type="flex" justify="center" align="middle">
          <Col xs={22} md={8} lg={5} className="col-card">
            <Card hoverable className="card-features" cover={<img alt="logo" src={card1} />}>
              <h3 className="card-title">Keep your logos, colors, and fonts organized</h3>
            </Card>
          </Col>
          <Col xs={22} md={8} lg={5} className="col-card">
            <Card hoverable className="card-features" cover={<img alt="unlimited" src={card2} />}>
              <h3 className="card-title">Share with others</h3>
            </Card>
          </Col>
          <Col xs={22} md={8} lg={5} className="col-card">
            <Card hoverable className="card-features" cover={<img alt="private" src={card3} />}>
              <h3 className="card-title">Public or private repositories</h3>
            </Card>
          </Col>
        </Row>
        <Title text="pricing" subtitle="It’s free for the first 6 months, so hurry up" />
        <Row className="section" type="flex" justify="center" align="middle">
          <Col xs={22} md={8} lg={6} className="col-card">
            <Card
              hoverable
              title="FREE"
              className="card-features pricing-card"
              style={{ width: '100%', textAlign: 'center' }}
            >
              <h2 className="card-title">Standard</h2>
              <h4 className="card-title">Upload logos, fonts and resources</h4>
              <h4 className="card-title">
                Create up to <strong>5</strong> repositories
              </h4>
              <h4 className="card-title">Unlimited downloads</h4>
              <h4 className="card-title">Private repository</h4>
              <h4 className="card-title line-through">Remove GitBrand brand</h4>

              <Button type="primary" style={{ marginTop: 12 }} onClick={this.handleStart}>
                Start now
              </Button>
            </Card>
          </Col>
          <Col xs={22} md={8} lg={6} className="col-card">
            <Card
              hoverable
              title="COMING SOON"
              className="pricing-card card-features"
              style={{ width: '100%', textAlign: 'center' }}
            >
              <h2 className="card-title">Premium</h2>
              <h4 className="card-title">Upload logos, fonts and resources</h4>
              <h4 className="card-title">
                Create up to <strong>unlimited</strong> repositories
              </h4>
              <h4 className="card-title">Unlimited downloads</h4>
              <h4 className="card-title">Private repository</h4>
              <h4 className="card-title">Remove GitBrand brand</h4>
              <h4 className="card-title">and more ...</h4>
            </Card>
          </Col>
        </Row>
        <Title text="contact" subtitle="Drop us a message, we will reply, I promisse" />
        <Contact />
      </HomeStyles>
    );
  }
}

const mapStateToProps = (state) => ({
  uid: state.user.uid,
});

export default withRouter(connect(mapStateToProps, { updateSettings })(Home));
