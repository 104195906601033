import styled from 'styled-components';

const FontContainerStyles = styled.span`
  ${({ type }) =>
    type === 'table'
      ? `
      background: #252525;
      color #ffffff;
      padding: 2px 6px;
      border: 2px solid #252525;
      border-radius: 2px;
      `
      : `
      background: #ffffff;
      color #252525;
      font-size: 26px;
  `};

  text-align: center;
  font-family: ${({ fontname }) => fontname};
  @font-face {
    font-family: ${({ fontname }) => fontname};
    src: url(${({ fonturl }) => fonturl});
    font-weight: normal;
    font-style: normal;
  }
`;

export default FontContainerStyles;
