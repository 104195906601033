import './App.css';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';
import { connect } from 'react-redux';

import { login, logout } from './redux/user/actions';
import { auth } from './utils/firebase';
import { Header, Footer } from './components';
import { Home, Repo, EditRepo, Profile, NotFound, PrivacyPolicy } from './pages';

class App extends React.Component {
  state = {
    isEmbed: false,
  }

  componentDidMount() {
    const url = window.location.href;

    if (url.includes('/embed/')) {
      this.setState({ isEmbed: true })
    }

    auth.onAuthStateChanged((user) => {
      if (user) {
        const {
          displayName,
          email,
          uid,
          photoURL,
          metadata: { creationTime },
        } = user;
        this.props.login({
          displayName: displayName || '',
          email: email || '',
          uid: uid || '',
          photoURL: photoURL || '',
          creationTime: creationTime || '',
        });
      } else {
        this.props.logout();
      }
    });
  }

  render() {
    return (
      <Layout>
        {!this.state.isEmbed && <Header />}
        <Layout.Content>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/r" component={Repo} />
            <Route exact path="/r/:id" component={Repo} />
            <Route exact path="/repo/:id" component={Repo} />
            <Route exact path="/repository/:id" component={Repo} />
            <Route exact path="/e/:id" component={EditRepo} />
            <Route exact path="/edit/:id" component={EditRepo} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            <Route exact path="/embed/:id" component={Repo} />
            <Route exact path="/:id" component={Repo} />
            <Route component={NotFound} />
          </Switch>
        </Layout.Content>
        {!this.state.isEmbed && <Footer />}
      </Layout>
    );
  }
}

export default connect(undefined, {
  login,
  logout,
})(App);
