import styled from 'styled-components';

const Home = styled.div`
  background: #fff;

  .trusted-by {
    font-size: 16px;
    color: #ccc;
  }

  .trusted-logos {
    margin-top: 24px;
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      margin: 0 16px;
    }
  }

  .top-inner {
    height: 50px;
    width: 50px;
    line-height: 50px;
    border-radius: 4px;
    background-color: #252525;
    color: #fff;
    text-align: center;
    font-size: 20px;
  }

  .section {
    margin: 52px 0;
  }

  .card-features {
    width: 100%;
    margin: 12px 0;
    cursor: unset;
  }

  .content {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 500px;
    height: 95vh;
  }

  .start-btn {
    padding: 0 52px;
  }

  .title {
    font-size: 40px;
    width: 40%;
    font-weight: 300;
  }

  .card-title {
    text-align: center;
    font-weight: 300;
  }

  .pricing-card .ant-card-head {
    background: #252525;
    color: #fff;
    text-align: center;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  @media only screen and (max-width: 40em) {
    .content {
      background-image: none !important;
      min-height: 60vh;
      height: auto;
      padding: 8px 24px;
    }

    .title {
      font-size: 46px;
      width: 100%;
      font-weight: 300;
    }
  }

  .col-card {
    padding: 12px;
  }
`;

export default Home;
