const actions = {
  LOG_OUT: 'LOG_OUT',
  LOG_IN: 'LOG_IN',
  UPDATE: 'UPDATE',
};

const login = (user) => ({
  type: actions.LOG_IN,
  payload: user,
});

const update = (updates) => ({
  type: actions.UPDATE,
  payload: updates,
});

const logout = () => ({
  type: actions.LOG_OUT,
});

export { actions, login, logout, update };
