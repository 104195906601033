import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';

const ModalComp = (props) => <Modal {...props} />;

const StyledModal = styled(ModalComp)`
  .signin-btns {
    display: flex;
    flex-direction: column;
    button {
      margin: 12px 0;
      height: 44px !important;
      font-size: 16px !important;
      box-shadow: none !important;
      div {
        div {
          min-width: 20px !important;
        }
      }
    }
  }
  .title-section {
    text-align: center;
    margin: 24px 0;
    h3 {
      font-weight: 400;
      margin: 30px 0;
    }
  }
  .footer-section {
    text-align: center;
    margin: 24px 0;
  }
  .section {
    display: flex;
    justify-content: center;

    .section-in {
      width: 100%;
      max-width: 400px;
    }
  }
`;

export default StyledModal;
