const hexToCMYK = (hex) => {
  try {
    let computedC = 0;
    let computedM = 0;
    let computedY = 0;
    let computedK = 0;

    hex = hex.charAt(0) === '#' ? hex.substring(1, 7) : hex;

    // Invalid length of the input hex value!
    if (hex.length !== 6) {
      return '';
    }

    // Invalid digits in the input hex value!
    if (/[0-9a-f]{6}/i.test(hex) !== true) {
      return '';
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // BLACK
    if (r === 0 && g === 0 && b === 0) {
      return `cmyk(0, 0, 0, 100)`;
    }

    computedC = 1 - r / 255;
    computedM = 1 - g / 255;
    computedY = 1 - b / 255;

    const minCMY = Math.min(computedC, Math.min(computedM, computedY));

    computedC = Math.round(((computedC - minCMY) / (1 - minCMY)) * 100);
    computedM = Math.round(((computedM - minCMY) / (1 - minCMY)) * 100);
    computedY = Math.round(((computedY - minCMY) / (1 - minCMY)) * 100);
    computedK = Math.round(minCMY * 100);

    return `cmyk(${computedC}, ${computedM}, ${computedY}, ${computedK})`;
  } catch (error) {
    return '';
  }
};

export { hexToCMYK };
