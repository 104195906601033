import React from 'react';
import { Row, Col } from 'antd';
import { HashLink as Link } from 'react-router-hash-link';

import FooterStyles from './styles/Footer.styles';
import { logo_white, icon_white } from '../assets';

class Footer extends React.PureComponent {
  render() {
    return (
      <FooterStyles>
        <Row type="flex" justify="center" align="middle">
          <Col xs={24} md={10} lg={8}>
            <img width="173" height="30" loading="lazy" alt="GitBrand" src={logo_white} />
            <div className="newsletter">
              <p>
                Keep your team <br />
                in sync with your <br />
                branding
              </p>
            </div>
          </Col>
          <Col xs={24} md={6} lg={6}>
            <div className="footer-links">
              <Link smooth to="/#home">
                home
              </Link>
              <Link smooth to="/#features">
                features
              </Link>
              <Link smooth to="/#pricing">
                pricing
              </Link>
              <Link smooth to="/#contact">
                contact
              </Link>
            </div>
          </Col>
          <Col xs={24} md={6} lg={6}>
            <div className="footer-contact">
              <a href="mailto:hi@gitbrand.com">hi@gitbrand.com</a>
              <img alt="icon" width="100" height="100" loading="lazy" src={icon_white} />
            </div>
          </Col>
          <Col xs={24} className="final-footer">
            <h5>
              <a
                target="_blank"
                className="footer-link"
                rel="noreferrer noopener"
                href="https://gitbrand.com"
              >
                GitBrand
              </a>{' '}
              | Designed and developed by{' '}
              <a
                target="_blank"
                className="footer-link"
                rel="noreferrer noopener"
                href="https://enzrossi.com.br"
              >
                Alisson Enz
              </a>{' '}
              |{' '}
              <Link className="footer-link" to="/privacy">
                Privacy Police
              </Link>
            </h5>
          </Col>
        </Row>
      </FooterStyles>
    );
  }
}

export default Footer;
