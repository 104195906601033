import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortId from 'short-id';
import { BulbOutlined, CloseOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Input, Select, Upload, Button, Switch, message } from 'antd';

import Modal from './styles/Modal.styles';
import Card from './styles/LogoCard.styles';
import { Spin } from '../../components';
import { generateId, typeExists } from '../../utils/helpers';
import { storage, firestore, FieldValue } from '../../utils/firebase';
import { defaultLogos } from '../../utils/defaults';

const initialState = {
  isModalVisible: false,
  type: undefined,
  fileString: '',
  file: {},
  isLoading: false,
  name: '',
  darkBackground: false,
};

class AddLogo extends Component {
  state = initialState;

  showUploadModal = () => {
    this.setState({ isModalVisible: true });
  };

  hideUploadModal = (force) => {
    if (!force && this.state.isLoading) return;
    this.setState(initialState);
  };

  handleChangeType = (type) => {
    this.setState({ type });
  };

  uploadLogo = (file) => {
    this.setState({ file });
    const reader = new FileReader();

    reader.onload = (e) => {
      this.setState({ fileString: e.target.result });
    };

    reader.readAsDataURL(file);
    return false;
  };

  handleRemoveLogo = () => {
    this.setState({ fileString: '', file: {} });
  };

  handleSubmit = async () => {
    const { file, darkBackground } = this.state;
    const { repoId } = this.props;

    let { logos } = this.props;
    let { type, name } = this.state;
    const sort = logos.length + 1;

    if (type === '*') {
      name = name.trim();
      type = generateId(name);
    } else {
      try {
        const l = defaultLogos.filter((d) => d.type === type)[0];
        name = l.name || type;
      } catch (e) {
        name = type;
      }
    }

    if (type.length < 3 || name.length > 32 || name.length < 3) {
      return message.error('Please provide a valid type name');
    }

    if (file.type !== 'image/svg+xml') {
      return message.error('Please provide a valid svg file');
    }

    try {
      this.setState({ isLoading: true });
      const metadata = {
        cacheControl: 'public,max-age=7200',
        contentType: 'image/svg+xml',
      };
      const ref = storage.ref(`repositories/${repoId}/logos/${type}.svg`);
      await ref.put(file, metadata);
      const url = await ref.getDownloadURL();
      logos.push({
        darkBackground,
        url,
        name,
        type,
        sort,
        id: shortId.generate(),
      });
      const newValue = {
        logos,
        updatedAt: FieldValue.serverTimestamp(),
      };
      const merge = { merge: true };
      await firestore.doc(`repositories/${repoId}`).set(newValue, merge);
      this.hideUploadModal(true);
    } catch (error) {
      message.error(error.message || 'Error');
      this.setState({ isLoading: false });
    }
  };

  checkBtnEnabled = () => {
    const { type, name, fileString } = this.state;
    return type === '*' ? name && name.length > 3 && fileString : type && fileString;
  };

  render() {
    return (
      <>
        <Button
          size="large"
          icon={<UploadOutlined />}
          type="primary"
          onClick={this.showUploadModal}
        >
          Upload logo <small> .svg</small>
        </Button>
        <Modal
          title="Upload logo"
          footer={null}
          style={{ top: 20 }}
          onCancel={this.hideUploadModal}
          visible={this.state.isModalVisible}
        >
          <Spin spinning={this.state.isLoading} style={{ textAlign: 'center' }}>
            {this.state.fileString ? (
              <Card
                hoverable
                darkbackground={this.state.darkBackground}
                style={{ height: 240, width: '100%' }}
                actions={[<CloseOutlined key="close" onClick={this.handleRemoveLogo} />]}
              >
                <div style={{ width: '100%', height: '100%' }}>
                  <Switch
                    checked={this.state.darkBackground}
                    onChange={(darkBackground) => this.setState({ darkBackground })}
                    unCheckedChildren={<BulbOutlined />}
                    style={{
                      position: 'absolute',
                      border: '1px solid #fff',
                      top: 12,
                      right: 12,
                    }}
                  />
                  <img alt="" style={{ width: '100%', height: 141 }} src={this.state.fileString} />
                </div>
              </Card>
            ) : (
              <>
                <Upload.Dragger name="logo" accept="image/svg+xml" beforeUpload={this.uploadLogo}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    1. Click or drag a <strong>.svg</strong> file to this area to upload
                    <br />
                    <small style={{ color: '#FF4466' }}>
                      Remember to convert text to curves/outlines
                    </small>
                  </p>
                </Upload.Dragger>
                <div style={{ textAlign: 'center', marginTop: 8 }}>
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://en.wikipedia.org/wiki/Scalable_Vector_Graphics"
                  >
                    What is a <strong>.svg</strong> file?
                  </a>
                </div>
              </>
            )}
            <div style={{ display: 'flex', marginTop: 12 }}>
              <Select
                size="large"
                placeholder="2. File type"
                value={this.state.type}
                onChange={this.handleChangeType}
                style={{ flex: 1, marginRight: 12 }}
              >
                {defaultLogos.map((l) => (
                  <Select.Option
                    key={l.type}
                    value={l.type}
                    disabled={typeExists(this.props.logos, l.type)}
                  >
                    {l.name}
                  </Select.Option>
                ))}
                <Select.Option value="*">Other</Select.Option>
              </Select>
              {this.state.type === '*' && (
                <Input
                  autoFocus
                  name="name"
                  size="large"
                  autoComplete="off"
                  placeholder="2.1 Name"
                  maxLength={32}
                  value={this.state.name}
                  style={{ flex: 1, marginRight: 12 }}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              )}
              <Button
                size="large"
                type="primary"
                onClick={this.handleSubmit}
                disabled={!this.checkBtnEnabled()}
              >
                3. Submit
              </Button>
            </div>
          </Spin>
        </Modal>
      </>
    );
  }
}

AddLogo.propTypes = {
  logos: PropTypes.array,
  repoId: PropTypes.string.isRequired,
};

AddLogo.defaultProps = {
  logos: [],
};

export default AddLogo;
