import styled from 'styled-components';
import { Layout } from 'antd';

const Header = styled(Layout.Header)`
  background: #fff;
  padding: 20px 50px;
  height: auto;
  .avatar {
    cursor: pointer;
  }

  .flex-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .links-div a {
    font-size: 18px;
    padding: 0 12px;
    font-weight: 300;
  }

  .signin-btn {
    margin: 0 12px;
    padding: 0 24px;
    height: 32px;
  }

  .ant-avatar {
    margin: 0 12px;
    color: #252525;
    border: 1px solid #252525;
    background: #fff;
    line-height: 38px;
    font-size: 22px;
    width: 30px;
    height: 30px;
  }

  @media only screen and (max-width: 50em) {
    padding: 20px 12px;
    .flex-div {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 40em) {
    .links-div a {
      font-size: 16px;
      padding: 4px 8px;
    }

    .links-div .ant-btn {
      display: none;
    }

    .ant-avatar {
      margin: 0 8px;
      width: 26px;
      height: 26px;
      font-size: 20px;
      line-height: 30px;
    }
  }
`;
export default Header;
