import '@ant-design/compatible/assets/index.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  EyeOutlined,
  GlobalOutlined,
  HomeOutlined,
  LockOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Checkbox, Button, Modal, Input, message } from 'antd';

import { firestore } from '../../utils/firebase';

class Settings extends Component {
  static propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    password: PropTypes.string,
    url: PropTypes.string,
    isPublic: PropTypes.bool,
    repoId: PropTypes.string.isRequired,
  };

  state = {
    showModal: false,
    loading: false,
    name: '',
    description: '',
    password: '',
    url: '',
    isPublic: true,
  };

  handleChangeInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSave = async () => {
    const { name, description, password, url, isPublic } = this.state;

    if (!name || name.length < 3) {
      return message.error('The name of the repository needs to be at least 3 characters long');
    }

    this.setState({ loading: true });

    try {
      const updates = {
        name,
        description,
        password,
        url,
        isPublic,
      };
      await firestore.doc(`repositories/${this.props.repoId}`).set(updates, { merge: true });
      this.setState({ loading: false, showModal: false });
    } catch (error) {
      message.error(error.message || 'Error');
      this.setState({ loading: false });
    }
  };

  handleShowSettings = () => {
    const { name, description, password, url, isPublic } = this.props;
    this.setState(
      {
        name,
        description,
        password,
        url,
        isPublic,
      },
      () => this.setState({ showModal: true }),
    );
  };

  handleChangePrivate = (e) => {
    this.setState({ isPublic: !e.target.checked });
    if (!e.target.checked) {
      this.setState({ password: '' });
    }
  };

  handleViewRepository = () => {
    this.props.history.push(`/${this.props.repoId}`);
  };

  render() {
    return (
      <div className="center m-t-40">
        <Button
          icon={<EyeOutlined />}
          size="large"
          type="secondary"
          id="view-repository-button"
          style={{ marginRight: 12 }}
          onClick={this.handleViewRepository}
        >
          View Repository
        </Button>
        <Button
          size="large"
          type="primary"
          icon={<SettingOutlined />}
          id="open-setting-button"
          onClick={this.handleShowSettings}
        >
          Open Settings
        </Button>
        <Modal
          title="Repository settings"
          onOk={this.handleSave}
          visible={this.state.showModal}
          confirmLoading={this.state.loading}
          onCancel={() => this.setState({ showModal: false })}
        >
          <Form layout="vertical">
            <Form.Item label={<span className="required">Name</span>}>
              <Input
                autoFocus
                name="name"
                size="large"
                autoComplete="off"
                placeholder="E.g.: Apple"
                value={this.state.name}
                disabled={this.state.loading}
                onChange={this.handleChangeInput}
                prefix={<HomeOutlined />}
              />
            </Form.Item>
            <Form.Item label="Description">
              <Input.TextArea
                size="large"
                name="description"
                autoComplete="off"
                disabled={this.state.loading}
                value={this.state.description}
                onChange={this.handleChangeInput}
                autoSize={{ minRows: 2, maxRows: 4 }}
              />
            </Form.Item>
            <Form.Item label="Website">
              <Input
                name="url"
                size="large"
                autoComplete="off"
                placeholder="E.g.: apple.com"
                value={this.state.url}
                disabled={this.state.loading}
                onChange={this.handleChangeInput}
                prefix={<GlobalOutlined />}
              />
            </Form.Item>
            <Form.Item
              extra={
                !this.state.isPublic && <small>Leave in blank to keep it completely private</small>
              }
              label={
                <Checkbox
                  disabled={this.state.loading}
                  checked={!this.state.isPublic}
                  onChange={this.handleChangePrivate}
                >
                  Private
                </Checkbox>
              }
            >
              <Input.Password
                size="large"
                name="password"
                autoComplete="off"
                placeholder="Password"
                value={this.state.password}
                disabled={this.state.isPublic || this.state.loading}
                onChange={this.handleChangeInput}
                prefix={<LockOutlined />}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

Settings.defaultProps = {
  name: '',
  description: '',
  password: '',
  url: '',
  isPublic: true,
};

export default withRouter(Settings);
