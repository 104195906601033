import React, { Component } from 'react';
import { AppstoreOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Avatar, Row, Col, Divider, Modal, Tabs } from 'antd';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Joyride from 'react-joyride';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import joyrideSteps from './helpers/joyrideSteps';
import { logo } from '../../assets';
import ProfileStyles from './styles/Profile.styles';
import ProfileForm from './ProfileForm';
import Repositories from './Repositories';

class Profile extends Component {
  state = {
    welcomeModalVisible: false,
    runJoyride: false,
  };

  componentDidMount() {
    if (!this.props.uid) {
      return this.props.history.push('/');
    }
    if (this.props.location.search === '?new=1') {
      this.props.history.replace('/profile');
      this.setState({ welcomeModalVisible: true });
    }
  }

  handleGetStart = () => {
    this.setState({ welcomeModalVisible: false, runJoyride: true });
  };

  render() {
    if (!this.props.uid) return <div />;

    return (
      <ProfileStyles>
        <Helmet>
          <title>Profile - GitBrand</title>
        </Helmet>
        <Joyride
          steps={joyrideSteps}
          scrollToFirstStep={true}
          run={this.state.runJoyride}
          styles={{
            options: { primaryColor: '#252525', textColor: '#252525' },
          }}
        />
        <Row type="flex" justify="center" align="middle">
          <Col xs={22} md={16} lg={14}>
            <h1 id="Profile" className="profile-title">
              <Avatar
                icon={<UserOutlined />}
                size={42}
                src={this.props.photoURL}
                style={{ marginRight: 8, border: '1px solid #252525' }}
              />{' '}
              Profile
            </h1>
            <div className="container-divider">
              <Divider />
            </div>
            <h2 className="profile-created-at">
              Account created at{' '}
              <strong>
                {this.props.creationTime && new moment(this.props.creationTime).format('LL')}
              </strong>
            </h2>
          </Col>
          <Col xs={22} md={16} lg={14} style={{ marginTop: 24 }}>
            <Tabs defaultActiveKey="repositories">
              <Tabs.TabPane
                key="repositories"
                tab={
                  <>
                    <AppstoreOutlined />
                    Repositories
                  </>
                }
              >
                <Repositories />
              </Tabs.TabPane>
              <Tabs.TabPane
                key="account"
                tab={
                  <>
                    <UserOutlined /> Account
                  </>
                }
              >
                <ProfileForm />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>

        <Modal title="Welcome to GitBrand" footer={null} visible={this.state.welcomeModalVisible}>
          <div style={{ textAlign: 'center' }}>
            <img width="200" alt="GitBrand" src={logo} style={{ marginBottom: 24 }} />
            <h3>It's a pleasure to have you with us</h3>
            <p>
              Feel free to create your first repository and share with others. If you have any
              feedback, please let us know
            </p>
            <p>
              <strong>Enjoy :D</strong>
            </p>
            <Button type="primary" onClick={this.handleGetStart}>
              Let's go
            </Button>
          </div>
        </Modal>
      </ProfileStyles>
    );
  }
}

const mapStateToProps = (state) => ({
  uid: state.user.uid,
  photoURL: state.user.photoURL,
  creationTime: state.user.creationTime,
});

export default withRouter(connect(mapStateToProps)(Profile));
