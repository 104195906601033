import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LockOutlined } from '@ant-design/icons';
import { Divider, Button, Row, Col, Input } from 'antd';
import { Link } from 'react-router-dom';

import { Title, Spin } from '../../components';

class PasswordView extends Component {
  render() {
    return (
      <>
        <Title
          text="private repository"
          subtitle="Private repositories needs a password to unlock"
        />
        <Row type="flex" justify="center" align="middle">
          <Col xs={22} md={16} lg={14} style={{ textAlign: 'center' }}>
            <Spin spinning={this.props.isLoading}>
              <div style={{ margin: '36px 24px', textAlign: 'center' }}>
                <Input.Search
                  autoFocus
                  size="large"
                  enterButton="Unlock"
                  placeholder="Password"
                  style={{ width: '60%' }}
                  prefix={<LockOutlined />}
                  onSearch={(password) => this.props.handleGetRepoWithPass(password)}
                />
              </div>
              <Divider>or</Divider>
              <div style={{ margin: '36px 0', textAlign: 'center' }}>
                <Link to="/">
                  <Button type="primary" size="large">
                    Go to home
                  </Button>
                </Link>
              </div>
            </Spin>
          </Col>
        </Row>
      </>
    );
  }
}

PasswordView.propTypes = {
  isLoading: PropTypes.bool,
  handleGetRepoWithPass: PropTypes.func.isRequired,
};

PasswordView.defaultProps = {
  isLoading: false,
};

export default PasswordView;
