import styled from 'styled-components';
import { Layout } from 'antd';

const Footer = styled(Layout.Footer)`
  padding-top: 40px;
  background: #252525;
  color: #fff;

  .final-footer h5 {
    margin-top: 36px;
    color: #fff;
    text-align: center;
  }
  .footer-link {
    color: #fff;
    font-weight: bold;
  }
  .footer-links {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    a {
      color: #fff;
      padding: 8px 0;
      font-size: 16px;
    }
  }
  .footer-contact {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    a {
      color: #fff;
    }
  }
  .newsletter {
    margin-top: 36px;
    width: 80%;
    p {
      font-size: 18px;
    }
  }
  .ant-input-search {
    border: 1px solid #fff;
    border-radius: 6px;
  }
`;
export default Footer;
