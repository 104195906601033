import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popover, Modal, Row, Col, Card, Divider, Table, message } from 'antd';

import LogoContainerStyles from './styles/LogoContainer.styles';
import AddLogo from './AddLogo';
import List from './styles/List.styles';
import { firestore } from '../../utils/firebase';

class EditLogos extends Component {
  handleRemoveLogo = (logo) => {
    Modal.confirm({
      title: 'Do you want to delete this logo?',
      content: `Name: ${logo.name}`,
      onOk: async () => {
        try {
          const logos = this.props.logos.filter((l) => l.id !== logo.id);
          await firestore.doc(`repositories/${this.props.repoId}`).set({ logos }, { merge: true });
        } catch (error) {
          message.error(error.message || 'Error');
        }
      },
    });
  };
  render() {
    const logos = Object.values(this.props.logos);
    const columns = [
      {
        title: 'Logo',
        key: 'logo',
        dataIndex: 'logo',
        render: (a, logo) => {
          const imageView = (size) => (
            <LogoContainerStyles
              size={size}
              darkbackground={logo.type.includes('white') || logo.darkBackground}
            >
              <img src={logo.url} alt="" />
            </LogoContainerStyles>
          );
          return <Popover content={imageView('large')}>{imageView('small')}</Popover>;
        },
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: 'Actions',
        key: 'actions',
        dataIndex: 'actions',
        render: (a, logo) => (
          <Button
            icon={<DeleteOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => this.handleRemoveLogo(logo)}
          />
        ),
      },
    ];
    return (
      <Row type="flex" justify="center" align="middle">
        <Col xs={22} md={16} lg={14}>
          <Card id="logos-container" style={{ width: '100%' }}>
            <div style={{ textAlign: 'center' }}>
              <AddLogo logos={this.props.logos} repoId={this.props.repoId} />
            </div>
            <Divider />
            <List style={{ height: 'auto' }}>
              <Table
                bordered
                rowKey="id"
                size="small"
                columns={columns}
                style={{ width: '100%' }}
                dataSource={logos.sort((a, b) => a.sort - b.sort)}
                pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
              />
            </List>
          </Card>
        </Col>
      </Row>
    );
  }
}

EditLogos.propTypes = {
  logos: PropTypes.array,
  repoId: PropTypes.string.isRequired,
};

EditLogos.defaultProps = {
  logos: [],
};

export default EditLogos;
