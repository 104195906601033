import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortId from 'short-id';
import { CloseOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Tabs, Table, Input, Select, Upload, Card, Button, message } from 'antd';
import * as opentype from 'opentype.js';

// Google fonts api
// AIzaSyA6Lnt_QnWfd1yBThYysuPoWBtoC1BXSbs

import Modal from './styles/Modal.styles';
import { Spin } from '../../components';
import { generateId, typeExists } from '../../utils/helpers';
import { storage, firestore, FieldValue } from '../../utils/firebase';
import { defaultFonts } from '../../utils/defaults';
import columns from './helpers/fontColumns';

const initialState = {
  isModalVisible: false,
  type: undefined,
  file: {},
  isLoading: false,
  name: '',
  fontFamily: '',
  fontSubfamily: '',
  postScriptName: '',
  designer: '',
};

class AddFont extends Component {
  state = initialState;

  showUploadModal = () => {
    this.setState({ isModalVisible: true });
  };

  hideUploadModal = (force) => {
    if (!force && this.state.isLoading) return;
    this.setState(initialState);
  };

  handleChangeType = (type) => {
    this.setState({ type });
  };

  uploadFont = (file) => {
    const ext = file.name.split('.').pop();
    if (!ext === 'ttf') {
      return message.error('Please upload a .ttf file');
    }
    const reader = new FileReader();

    reader.onload = (e) => {
      opentype.load(e.target.result, (error, font) => {
        if (error) {
          return message.error('Error');
        }
        const {
          names: { fontFamily, fontSubfamily, postScriptName, designer },
        } = font;
        this.setState({
          file,
          fontFamily: (fontFamily || {}).en || '',
          fontSubfamily: (fontSubfamily || {}).en || '',
          postScriptName: (postScriptName || {}).en || '',
          designer: (designer || {}).en || '',
        });
      });
    };

    reader.readAsDataURL(file);

    return false;
  };

  handleRemoveFont = () => {
    this.setState({
      file: {},
      fontFamily: '',
      fontSubfamily: '',
      postScriptName: '',
      designer: '',
    });
  };

  handleSubmit = async () => {
    const { file, fontFamily, fontSubfamily, postScriptName, designer } = this.state;
    const { repoId } = this.props;
    let { fonts } = this.props;
    let { type, name } = this.state;
    const sort = fonts.length + 1;

    if (type === '*') {
      name = name.trim();
      type = generateId(name);
    } else {
      try {
        const l = defaultFonts.filter((d) => d.type === type)[0];
        name = l.name || type;
      } catch (e) {
        name = type;
      }
    }

    if (type.length < 3 || name.length > 32 || name.length < 3) {
      return message.error('Please provide a valid type name');
    }

    try {
      this.setState({ isLoading: true });
      const metadata = {
        cacheControl: 'public,max-age=7200',
        contentType: 'font/opentype',
      };
      const ref = storage.ref(`repositories/${repoId}/fonts/${file.name}.ttf`);
      await ref.put(file, metadata);
      const url = await ref.getDownloadURL();
      fonts.push({
        url,
        name,
        type,
        sort,
        fontFamily,
        fontSubfamily,
        postScriptName,
        designer,
        id: shortId.generate(),
      });
      const newValue = {
        fonts,
        updatedAt: FieldValue.serverTimestamp(),
      };
      const merge = { merge: true };
      await firestore.doc(`repositories/${repoId}`).set(newValue, merge);
      this.hideUploadModal(true);
    } catch (error) {
      message.error(error.message || 'Error');
      this.setState({ isLoading: false });
    }
  };

  checkBtnEnabled = () => {
    const { type, name, file } = this.state;
    return type === '*' ? name && name.length > 3 && !!file.name : type && !!file.name;
  };

  render() {
    const { fontFamily, fontSubfamily, designer } = this.state;
    return (
      <>
        <Button
          size="large"
          icon={<UploadOutlined />}
          type="primary"
          onClick={this.showUploadModal}
        >
          Add font <small> .ttf</small>
        </Button>
        <Modal
          title="Upload font"
          footer={null}
          style={{ top: 20 }}
          onCancel={this.hideUploadModal}
          visible={this.state.isModalVisible}
        >
          <Tabs defaultActiveKey="upload">
            <Tabs.TabPane tab="Upload" key="upload">
              <Spin spinning={this.state.isLoading} style={{ textAlign: 'center' }}>
                {this.state.file.name ? (
                  <Card
                    hoverable
                    style={{ width: '100%' }}
                    actions={[<CloseOutlined key="close" onClick={this.handleRemoveFont} />]}
                  >
                    <Table
                      bordered
                      size="small"
                      rowKey="fontFamily"
                      columns={columns}
                      pagination={false}
                      dataSource={[{ fontFamily, fontSubfamily, designer }]}
                    />
                  </Card>
                ) : (
                  <Upload.Dragger
                    name="font"
                    accept="font/opentype"
                    fileList={this.state.file.name ? [this.state.file] : []}
                    beforeUpload={this.uploadFont}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      1. Click or drag a <strong>.ttf</strong> file to this area to upload
                    </p>
                  </Upload.Dragger>
                )}
                <div style={{ display: 'flex', marginTop: 12 }}>
                  <Select
                    size="large"
                    placeholder="2. File type"
                    value={this.state.type}
                    onChange={this.handleChangeType}
                    style={{ flex: 1, marginRight: 12 }}
                  >
                    {defaultFonts.map((f) => (
                      <Select.Option
                        key={f.type}
                        value={f.type}
                        disabled={typeExists(this.props.fonts, f.type)}
                      >
                        {f.name}
                      </Select.Option>
                    ))}

                    <Select.Option value="*">Other font</Select.Option>
                  </Select>
                  {this.state.type === '*' && (
                    <Input
                      autoFocus
                      name="name"
                      size="large"
                      placeholder="2.1 Name"
                      maxLength={32}
                      value={this.state.name}
                      style={{ flex: 1, marginRight: 12 }}
                      onChange={(e) => this.setState({ name: e.target.value })}
                    />
                  )}
                  <Button
                    size="large"
                    type="primary"
                    onClick={this.handleSubmit}
                    disabled={!this.checkBtnEnabled()}
                  >
                    3. Submit
                  </Button>
                </div>
              </Spin>
            </Tabs.TabPane>
            <Tabs.TabPane
              disabled
              key="google"
              tab={
                <span>
                  Google Fonts <small>soon</small>
                </span>
              }
            ></Tabs.TabPane>
            <Tabs.TabPane
              disabled
              key="adobe"
              tab={
                <span>
                  Adobe Fonts <small>soon</small>
                </span>
              }
            ></Tabs.TabPane>
          </Tabs>
        </Modal>
      </>
    );
  }
}

AddFont.propTypes = {
  fonts: PropTypes.array,
  repoId: PropTypes.string.isRequired,
};

AddFont.defaultProps = {
  fonts: [],
};

export default AddFont;
