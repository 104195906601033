import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/performance';
import 'firebase/analytics';

if (!firebase.apps.length) {
  const firebaseConfig = {
    apiKey: 'AIzaSyC1MBRYKX1n_D_7KyNC8TlemtFFxZVnDAo',
    authDomain: 'gitbrand.com',
    databaseURL: 'https://brandkitpro.firebaseio.com',
    projectId: 'brandkitpro',
    storageBucket: 'brandkitpro.appspot.com',
    messagingSenderId: '119215414772',
    appId: '1:119215414772:web:88d7ef2607341ac202eda1',
    measurementId: 'G-LZDYM47V18',
  };

  firebase.initializeApp(firebaseConfig);
}

const database = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.functions();
const firestore = firebase.firestore();
const perf = firebase.performance();
const analytics = firebase.analytics();
const firDelete = firebase.firestore.FieldValue.delete();
const FieldValue = firebase.firestore.FieldValue;

export {
  analytics,
  firebase,
  firestore,
  database,
  auth,
  storage,
  perf,
  functions,
  firDelete,
  FieldValue,
};
