import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';

import { Title } from './index';
import { notFound } from '../assets';

class NotFound extends Component {
  render() {
    const { text, subtitle } = this.props;
    return (
      <>
        <Title text={text} subtitle={subtitle} />
        <Row type="flex" justify="center" align="middle">
          <Col xs={22} md={16} lg={14} style={{ textAlign: 'center' }}>
            <div style={{ margin: '36px 0' }}>
              <img width="60%" height="auto" src={notFound} alt="404" />
            </div>
            <div style={{ margin: '48px 0' }}>
              <Link to="/">
                <Button type="primary" size="large">
                  Back Home
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

NotFound.propTypes = {
  text: PropTypes.string,
  subtitle: PropTypes.string,
};

NotFound.defaultProps = {
  text: 'Page not found',
  subtitle: 'Sorry, the page you visited does not exist.',
};

export default NotFound;
