import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortId from 'short-id';
import { CloseOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Table, Input, Select, Upload, Card, Button, Tag, message } from 'antd';

import Modal from './styles/Modal.styles';
import { Spin } from '../../components';
import { generateId, typeExists } from '../../utils/helpers';
import { getExtensionColor } from '../../utils/helpers';
import { storage, firestore, FieldValue } from '../../utils/firebase';
import { defaultResources } from '../../utils/defaults';

const initialState = {
  isModalVisible: false,
  type: undefined,
  file: {},
  isLoading: false,
  name: '',
  dataSource: [],
};

class AddResource extends Component {
  state = initialState;

  showUploadModal = () => {
    this.setState({ isModalVisible: true });
  };

  hideUploadModal = (force) => {
    if (!force && this.state.isLoading) return;
    this.setState(initialState);
  };

  handleChangeType = (type) => {
    this.setState({ type });
  };

  uploadResources = (file) => {
    const fileName = file.name;
    const name = fileName.replace(/\.[^/.]+$/, '');
    const ext = fileName.split('.').pop();

    this.setState({
      dataSource: [{ name, ext }],
      file,
    });

    return false;
  };

  handleRemoveResource = () => {
    this.setState({ file: {}, dataSource: [] });
  };

  handleSubmit = async () => {
    const { file } = this.state;
    const { repoId } = this.props;
    let { resources } = this.props;
    let { type, name } = this.state;
    const sort = resources.length + 1;

    if (type === '*') {
      name = name.trim();
      type = generateId(name);
    } else {
      try {
        const l = defaultResources.filter((d) => d.type === type)[0];
        name = l.name || type;
      } catch (e) {
        name = type;
      }
    }

    if (type.length < 3 || name.length > 32 || name.length < 3) {
      return message.error('Please provide a valid type name');
    }

    try {
      this.setState({ isLoading: true });
      const metadata = {
        cacheControl: 'public,max-age=7200',
        contentType: file.type || 'application/octet-stream',
      };
      const ext = file.name.split('.').pop();
      const ref = storage.ref(`repositories/${repoId}/resources/${type}.${ext}`);
      await ref.put(file, metadata);
      const url = await ref.getDownloadURL();
      resources.push({ url, name, type, sort, ext, id: shortId.generate() });
      const newValue = {
        resources,
        updatedAt: FieldValue.serverTimestamp(),
      };
      const merge = { merge: true };
      await firestore.doc(`repositories/${repoId}`).set(newValue, merge);
      this.hideUploadModal(true);
    } catch (error) {
      message.error(error.message || 'Error');
      this.setState({ isLoading: false });
    }
  };

  checkBtnEnabled = () => {
    const { type, name, file } = this.state;
    return (type || '').includes('*')
      ? name && name.length > 3 && !!file.name
      : type && !!file.name;
  };

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'File Type',
        key: 'ext',
        dataIndex: 'ext',
        render: (ext) => <Tag color={getExtensionColor(ext)}>{(ext || '').toUpperCase()}</Tag>,
      },
    ];
    return (
      <>
        <Button
          size="large"
          icon={<UploadOutlined />}
          type="primary"
          onClick={this.showUploadModal}
        >
          Upload resource <small> (any file type)</small>
        </Button>
        <Modal
          title="Upload resource"
          footer={null}
          style={{ top: 20 }}
          onCancel={this.hideUploadModal}
          visible={this.state.isModalVisible}
        >
          <Spin spinning={this.state.isLoading} style={{ textAlign: 'center' }}>
            {this.state.file.name ? (
              <Card
                hoverable
                style={{ width: '100%' }}
                actions={[<CloseOutlined key="close" onClick={this.handleRemoveResource} />]}
              >
                <Table
                  bordered
                  size="small"
                  columns={columns}
                  pagination={false}
                  dataSource={this.state.dataSource}
                />
              </Card>
            ) : (
              <Upload.Dragger name="resource" beforeUpload={this.uploadResources}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">1. Click or drag a file to this area to upload</p>
              </Upload.Dragger>
            )}
            <div style={{ display: 'flex', marginTop: 12 }}>
              <Select
                size="large"
                placeholder="2. File type"
                value={this.state.type}
                onChange={this.handleChangeType}
                style={{ flex: 1, marginRight: 12 }}
              >
                <Select.OptGroup label="Resource">
                  {defaultResources.map((r) => (
                    <Select.Option
                      key={r.type}
                      value={r.type}
                      disabled={typeExists(this.props.resources, r.type)}
                    >
                      {r.name}
                    </Select.Option>
                  ))}
                  <Select.Option value="*">Other resource</Select.Option>
                </Select.OptGroup>
              </Select>
              {(this.state.type || '').includes('*') && (
                <Input
                  autoFocus
                  name="name"
                  size="large"
                  autoComplete="off"
                  placeholder="2.1 Name"
                  maxLength={32}
                  value={this.state.name}
                  style={{ flex: 1, marginRight: 12 }}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              )}
              <Button
                size="large"
                type="primary"
                onClick={this.handleSubmit}
                disabled={!this.checkBtnEnabled()}
              >
                3. Submit
              </Button>
            </div>
          </Spin>
        </Modal>
      </>
    );
  }
}

AddResource.propTypes = {
  resources: PropTypes.array,
  repoId: PropTypes.string.isRequired,
};

AddResource.defaultProps = {
  resources: [],
};

export default AddResource;
