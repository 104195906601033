import styled from 'styled-components';
import { Card } from 'antd';

const FontCardStyles = styled(Card)`
  @font-face {
    font-family: ${(props) => props.fontname};
    src: url(${(props) => props.fonturl});
    font-weight: normal;
    font-style: normal;
  }
  .ant-card-body {
    padding-top: 12px;
  }
  textarea {
    font-family: ${(props) => props.fontname};
    border: none;
    overflow: auto;
    outline: 1px solid transparent;
    border-style: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none;
    background: transparent;
    border-radius: 0;
    max-height: 150px;
    margin-top: 12px;
    &:active,
    &:focus,
    &:hover {
      border: none;
      overflow: auto;
      outline: 1px solid transparent;
      border-style: none;
    }
  }

  .ant-card-body {
    background-size: 12px 12px;
    background-image: linear-gradient(to right, #fafafa 1px, transparent 1px),
      linear-gradient(to bottom, #fafafa 1px, transparent 1px);
  }
`;

export default FontCardStyles;
