import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';

import user from './user/reducers';
import settings from './settings/reducers';
import repositories from './repositories/reducers';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['user', 'repositories'],
  blacklist: ['settings'],
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  user,
  settings,
  repositories,
});

const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
const persistor = persistStore(store);

export { store, persistor };
