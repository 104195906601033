import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#252525' }} spin />;

export default ({ spinning, children, style }) => (
  <Spin spinning={spinning} style={style} indicator={antIcon}>
    {children}
  </Spin>
);
