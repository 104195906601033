import React, { Component } from 'react';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Typography, Tag, Card, Col, Modal, message } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import { firestore } from '../../utils/firebase';

class RepoCard extends Component {
  handleEditRepo = () => {
    const { id } = this.props.repo;
    this.props.history.push(`/edit/${id}`);
  };

  handleOpenRepo = () => {
    const { id } = this.props.repo;
    this.props.history.push(`/${id}`);
  };

  handleDeleteRepoConfirmation = () => {
    const { name } = this.props.repo;
    Modal.confirm({
      title: `Do you want to delete ${name}?`,
      content: 'If you delete, all the data will be removed and it cannot be restored',
      onOk: this.handleDeleteRepo,
    });
  };

  handleDeleteRepo = async () => {
    try {
      const { id } = this.props.repo;
      await firestore.doc(`repositories/${id}`).delete();
      message.success('Repository deleted with success');
    } catch (error) {
      message.error(error.message || 'Error');
    }
  };

  render() {
    const { repo } = this.props;
    let logoUrl =
      'https://firebasestorage.googleapis.com/v0/b/brandkitpro.appspot.com/o/_prod%2Fmissing-logo.svg?alt=media&token=b9f157b1-c3e4-4c2f-88b6-59ed8ccd551a';
    if (repo.logos && repo.logos.length > 0) {
      logoUrl = repo.logos[0].url;
    }
    return (
      <Col xs={22} md={12} lg={8} style={{ marginTop: 12 }}>
        <Card
          className="repo-card"
          title={
            <div>
              <span style={{ fontWeight: 500, whiteSpace: 'normal' }}>
                {repo.name}{' '}
                {!repo.isPublic && (
                  <Tag style={{ fontSize: 10, lineHeight: '16px' }} color="red">
                    private
                  </Tag>
                )}
              </span>
              <br />
              <Typography.Text
                style={{ fontSize: 14 }}
                copyable={{ text: `https://gitbrand.com/${repo.id}` }}
              >
                <a
                  href={`https://gitbrand.com/${repo.id}`}
                  style={{ fontSize: 12, whiteSpace: 'normal' }}
                >
                  {'https://gitbrand.com/'}
                  <strong>{repo.id}</strong>
                </a>
              </Typography.Text>
            </div>
          }
          style={{ width: '100%' }}
          actions={[
            <EyeOutlined key="eye" onClick={this.handleOpenRepo} />,
            <EditOutlined key="edit" onClick={this.handleEditRepo} />,
            <DeleteOutlined key="delete" onClick={this.handleDeleteRepoConfirmation} />,
          ]}
        >
          <Link to={`/${repo.id}`}>
            <ReactSVG
              src={logoUrl}
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'height: 160px; width: 100%');
              }}
            />
          </Link>
        </Card>
      </Col>
    );
  }
}

export default withRouter(RepoCard);
