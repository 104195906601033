import '@ant-design/compatible/assets/index.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HighlightOutlined, LoadingOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Spin, Input, Button, Card, Row, Col, Modal, message as messageAnt } from 'antd';

import { isValidEmail } from '../../utils/helpers';
import { firebase, firestore } from '../../utils/firebase';

const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class Contact extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    isLoading: false,
  };

  handleChangeField = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFormSubmit = async () => {
    try {
      const { name, email, subject, message } = this.state;

      if (!name || !email || !subject || !message) {
        return messageAnt.error('Please fill all required fields');
      }

      if (!isValidEmail(email)) {
        return messageAnt.error('Invalid email');
      }

      this.setState({ isLoading: true });
      const contact = {
        name,
        email,
        subject,
        message,
        answered: false,
        loggedUserId: this.props.uid,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      await firestore.collection('contacts').add(contact);
      Modal.success({
        title: `Thanks for your contact ${name.split(' ')[0]}`,
        content: "We'll reply you soon",
      });
      this.setState({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    } catch (error) {
      messageAnt.error(error.message);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <Row className="section" type="flex" justify="center" align="middle">
        <Col xs={22} md={12} lg={8}>
          <Spin indicator={spinIcon} spinning={this.state.isLoading}>
            <Card className="card-features" title={null} style={{ width: '100%' }}>
              <Form layout="vertical">
                <Form.Item label={<span className="required">Name</span>}>
                  <Input
                    name="name"
                    size="large"
                    autoComplete="off"
                    placeholder="E.g.: John Senna"
                    value={this.state.name}
                    onChange={this.handleChangeField}
                    prefix={<UserOutlined />}
                  />
                </Form.Item>
                <Form.Item label={<span className="required">Email</span>}>
                  <Input
                    size="large"
                    name="email"
                    type="email"
                    autoComplete="off"
                    placeholder="E.g.: john@gmail.com"
                    value={this.state.email}
                    onChange={this.handleChangeField}
                    prefix={<MailOutlined />}
                  />
                </Form.Item>
                <Form.Item label={<span className="required">Subject</span>}>
                  <Input
                    size="large"
                    name="subject"
                    autoComplete="off"
                    placeholder="E.g.: I love it"
                    value={this.state.subject}
                    onChange={this.handleChangeField}
                    prefix={<HighlightOutlined />}
                  />
                </Form.Item>
                <Form.Item label={<span className="required">Message</span>}>
                  <Input.TextArea
                    size="large"
                    name="message"
                    autoComplete="off"
                    value={this.state.message}
                    onChange={this.handleChangeField}
                    autoSize={{ minRows: 3, maxRows: 6 }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button size="large" type="primary" onClick={this.handleFormSubmit}>
                    Send
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Spin>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  uid: state.user.uid,
});

export default connect(mapStateToProps)(Contact);
