import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Breadcrumb, Tag, Input, Divider, Row, Col, Tooltip, Modal, message } from 'antd';
import {
  EditOutlined,
  EyeOutlined,
  FlagOutlined,
  GlobalOutlined,
  HomeOutlined,
  RocketOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { firebase, firestore } from '../utils/firebase';
import { addhttp } from '../utils/helpers';
import RepoTitleStyles from './styles/RepoTitle.styles';

class RepoTitle extends Component {
  state = {
    isReportModalVisible: false,
    isLoading: false,
    message: '',
  };

  showReportModal = () => {
    this.setState({ isReportModalVisible: true });
  };

  editRepo = () => {
    this.props.history.push(`/edit/${this.props.repoId}`);
  };

  seeRepo = () => {
    this.props.history.push(`/${this.props.repoId}`);
  };

  hideReportModal = () => {
    this.setState({
      message: '',
      isLoading: false,
      isReportModalVisible: false,
    });
  };

  handleSubmitReport = async () => {
    if (!this.state.message || this.state.message.length < 10) {
      return message.error('Please write something (min 10 characters)');
    }

    try {
      this.setState({ isLoading: true });
      const report = {
        resolved: false,
        message: this.state.message,
        createdByName: this.props.displayName,
        createdByEmail: this.props.email,
        createdById: this.props.uid,
        repoId: this.props.repoId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      await firestore.collection('reports').add(report);
      message.success('Thanks for your report');
      this.hideReportModal();
    } catch (error) {
      message.error(error.message || 'Error');
      this.setState({ isLoading: false });
    }
  };

  renderIcon = () => {
    if (this.props.members.indexOf(this.props.uid) === -1) {
      return (
        <Tooltip title="Report abuse">
          <FlagOutlined onClick={this.showReportModal} />
        </Tooltip>
      );
    }

    const { pathname } = this.props.location;
    if (pathname.startsWith('/e/') || pathname.startsWith('/edit/')) {
      return (
        <Tooltip title="See repository">
          <EyeOutlined onClick={this.seeRepo} />
        </Tooltip>
      );
    }

    return (
      <Tooltip title="Edit repository">
        <EditOutlined onClick={this.editRepo} />
      </Tooltip>
    );
  };

  getUpdatedAt = () => {
    if (!this.props.updatedAt) return null;
    let updatedAt;
    try {
      updatedAt = new moment(this.props.updatedAt.toMillis()).format('LL');
    } catch (e) {
      updatedAt = new moment(this.props.updatedAt).format('LL');
    }
    return updatedAt;
  };

  renderBreadcrumb = () => {
    const { pathname } = this.props.location;
    if (!pathname.startsWith('/e/') || !pathname.startsWith('/edit/')) return;
    return (
      <Breadcrumb>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/profile">
          <UserOutlined />
          <span>Profile</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{this.props.name}</Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  render() {
    return (
      <RepoTitleStyles>
        <Row type="flex" justify="center" align="middle">
          <Col xs={22} md={20} lg={18}>
            <div className="title-container">
              <div>
                {this.renderBreadcrumb()}
                <h1 id={this.props.name} className="repo-name">
                  {this.props.name} {!this.props.isPublic && <Tag color="red">private</Tag>}{' '}
                  {this.renderIcon()}
                </h1>
              </div>
              <div className="extra">
                {this.props.url && (
                  <a target="_blank" rel="noreferrer noopener" href={addhttp(this.props.url)}>
                    <h2 className="repo-updated">
                      <GlobalOutlined /> {this.props.url}
                    </h2>
                  </a>
                )}
                {this.props.updatedAt && (
                  <h2 className="repo-updated">
                    <RocketOutlined /> Last updated at <strong>{this.getUpdatedAt()}</strong>
                  </h2>
                )}
              </div>
            </div>

            <Divider />

            {this.props.description && (
              <p style={{ marginTop: 24, textAlign: 'center' }}>{this.props.description}</p>
            )}
          </Col>
        </Row>
        <Modal
          okText="Report"
          title="Report abuse"
          onOk={this.handleSubmitReport}
          onCancel={this.hideReportModal}
          confirmLoading={this.state.isLoading}
          visible={this.state.isReportModalVisible}
        >
          <Input.TextArea
            placeholder="Tell us what happened (min 10 characters)"
            rows={4}
            value={this.state.message}
            onChange={(e) => this.setState({ message: e.target.value })}
          />
        </Modal>
      </RepoTitleStyles>
    );
  }
}

RepoTitle.propTypes = {
  url: PropTypes.string,
  isPublic: PropTypes.bool,
  members: PropTypes.array,
  updatedAt: PropTypes.object,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  repoId: PropTypes.string.isRequired,
};

RepoTitle.defaultProps = {
  url: '',
  description: '',
  members: [],
  updatedAt: {},
  isPublic: true,
};

const mapStateToProps = (state) => ({
  uid: state.user.uid,
  email: state.user.email,
  displayName: state.user.displayName,
});

export default withRouter(connect(mapStateToProps)(RepoTitle));
