import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Col, Typography } from 'antd';
import tinycolor from 'tinycolor2';

import { hexToCMYK } from '../../utils/colors';
import ColorCardStyles from './styles/ColorCard.styles';

function ColorCard({ color }) {
  const c = tinycolor(color.hex);
  return (
    <Col xs={12} md={6} lg={5} style={{ padding: 8 }}>
      <ColorCardStyles
        color={c.isLight() ? '#252525' : '#ffffff'}
        title={<span style={{ fontWeight: 500 }}>{color.name}</span>}
        style={{ width: '100%' }}
        className="ant-card-bordered-shadow"
      >
        <div className="color-background" style={{ background: color.hex }}>
          <Typography.Text
            strong
            copyable={{ text: color.hex }}
            style={{
              color: c.isLight() ? '#252525' : '#ffffff',
              fontSize: 18,
            }}
          >
            {color.hex}
          </Typography.Text>
        </div>
        <Collapse bordered={false}>
          <Collapse.Panel header="More formats" key="1">
            <div>
              <div className="m-t-6">
                <Typography.Text copyable={{ text: c.toRgbString() }}>
                  {c.toRgbString()}
                </Typography.Text>
              </div>
              <div className="m-t-6">
                <Typography.Text copyable={{ text: c.toHslString() }}>
                  {c.toHslString()}
                </Typography.Text>
              </div>
              <div className="m-t-6">
                <Typography.Text copyable={{ text: c.toHsvString() }}>
                  {c.toHsvString()}
                </Typography.Text>
              </div>
              <div className="m-t-6">
                <Typography.Text copyable={{ text: hexToCMYK(color.hex) }}>
                  {hexToCMYK(color.hex)}
                </Typography.Text>
              </div>
            </div>
          </Collapse.Panel>
        </Collapse>
      </ColorCardStyles>
    </Col>
  );
}

ColorCard.propTypes = {
  color: PropTypes.object.isRequired,
};

ColorCard.defaultProps = {};

export default ColorCard;
