import styled from 'styled-components';

const ColorContainerStyles = styled.div`
  width: ${({ size }) => (size === 'large' ? '200px' : '80px')};
  height: ${({ size }) => (size === 'large' ? '200px' : '40px')};
  padding: ${({ size }) => (size === 'large' ? '12px' : '4px')};
  border: 1px solid #eee;
  border-radius: 4px;
  background: ${({ background }) => background};

  .color-container {
    width: 70px;
    height: 70px;
    border-radius: 2px;
  }
`;

export default ColorContainerStyles;
