import React, { Component } from 'react';

import { NotFound } from '../../components';

class NotFoundPage extends Component {
  render() {
    return <NotFound />;
  }
}

export default NotFoundPage;
